import React from 'react';
import classNames from 'classnames';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { ColorByUsage } from './UIConstants';
import SkwrCheckbox from './SkwrCheckbox';

const useStyles = makeStyles(theme => ({
  formControl: {
    color: ColorByUsage.labelForeground,
  },
}));

const SkwrCheckboxLabel = ({
  checked,
  className,
  label,
  disabled,
  onChange,
  id,
}) => {
  const handleChange = () => {
    if (onChange) {
      onChange(!checked, id);
    }
  };
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classNames(className, classes.formControl)}
      control={(
        <SkwrCheckbox
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
      label={label}
    />
  );
};

export default SkwrCheckboxLabel;
