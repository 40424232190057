import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';

// ************************************************************ API REQUEST

/**
 * Retrieves the array of exports of a given project
 * @param {string} projectUuid uuid of the project
 */
export const getExports = (projectUuid, nextUrl) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/experience_exports?project.uuid=${projectUuid}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.log('error in first');
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};

/**
 * Increment export list
 * @param {string} nextUrl url of the next page
 */
export const getNextPage = (nextUrl) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}${nextUrl}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.log('error in second');
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};

/**
 * Add an export to the project
 * @param {string} projectUuid uuid of the project
 */
export const createExports = (exportName, exportTechnicalName, replayToken, projectUuid) => {
  const parsedValues = {
    name: exportName,
    technicalName: exportTechnicalName,
    playlistGeneratorReplayToken: replayToken,
    description: null, // display descritpion in future
    project: `projects/${projectUuid}`,
  };
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/experience_exports`, JSON.stringify(parsedValues), {
      headers: authHeader(),
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      if (error.response.status === 400) {
        if (error.response.data['@type'] === 'ConstraintViolationList') {
          if (error.response.data.violations[0].message !== '') {
            console.error(error.response.data.violations[0].message);
          }
          if (error.response.data.violations[0].propertyPath === 'name') {
            reject(new Error('exports.error.unauthorizedExportName'));
            return;
          }
        }
      }

      rejectUnfilteredError('createExports', error, reject);
    });
  });
};

/**
 * Delete an export to the project
 * @param {string} exportIri iri of export to delete
 */
export const deleteExports = (exportIri) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${exportIri}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(exportIri);
    }).catch((error) => {
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};

/**
 * Get the download url for a given export
 * @param {string} exportIri iri of export to download
 */
export const getDownloadUrl = (exportIri) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}${exportIri}/download`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      rejectUnfilteredError('getDataKeys', error, reject);
    });
  });
};
