/**
 * File: nodesStateConstants.js
 */
const ACTIONS = {
  // NODES REQUESTS
  // ----------
  CLEAR_ERROR: 'NODES_CLEAR_ERROR',

  NEW_NODE_REQUEST: 'NEW_NODE_API_POST_REQUEST',
  NEW_NODE_SUCCESS: 'NEW_NODE_API_POST_SUCCESS',
  NEW_NODE_FAILURE: 'NEW_NODE_API_POST_FAILURE',

  CREATE_FORK_REQUEST: 'CREATE_FORK_API_POST_REQUEST',
  CREATE_FORK_SUCCESS: 'CREATE_FORK_API_POST_SUCCESS',
  CREATE_FORK_FAILURE: 'CREATE_FORK_API_POST_FAILURE',

  CREATE_OPTIONAL_NODES_REQUEST: 'CREATE_OPTIONAL_NODES_API_POST_REQUEST',
  CREATE_OPTIONAL_NODES_SUCCESS: 'CREATE_OPTIONAL_NODES_API_POST_SUCCESS',
  CREATE_OPTIONAL_NODES_FAILURE: 'CREATE_OPTIONAL_NODES_API_POST_FAILURE',

  CHANGE_CONNECTABLES_COORDINATES_REQUEST: 'CHANGE_CONNECTABLES_COORDINATES_API_POST_REQUEST',
  CHANGE_CONNECTABLES_COORDINATES_SUCCESS: 'CHANGE_CONNECTABLES_COORDINATES_API_POST_SUCCESS',
  CHANGE_CONNECTABLES_COORDINATES_FAILURE: 'CHANGE_CONNECTABLES_COORDINATES_API_POST_FAILURE',

  CHANGE_NODE_LABEL_REQUEST: 'CHANGE_NODE_LABEL_API_POST_REQUEST',
  CHANGE_NODE_LABEL_SUCCESS: 'CHANGE_NODE_LABEL_API_POST_SUCCESS',
  CHANGE_NODE_LABEL_FAILURE: 'CHANGE_NODE_LABEL_API_POST_FAILURE',
  CHANGE_NODE_LABEL_SUCCESS_UNDOABLE: 'CHANGE_NODE_LABEL_SUCCESS_UNDOABLE',

  PERSONNALIZE_NODE_REQUEST: 'PERSONNALIZE_NODE_API_POST_REQUEST',
  PERSONNALIZE_NODE_SUCCESS: 'PERSONNALIZE_NODE_API_POST_SUCCESS',
  PERSONNALIZE_NODE_FAILURE: 'PERSONNALIZE_NODE_API_POST_FAILURE',

  CHANGE_LINKS_TARGET_REQUEST: 'CHANGE_LINKS_TARGET_API_POST_REQUEST',
  CHANGE_LINKS_TARGET_SUCCESS: 'CHANGE_LINKS_TARGET_API_POST_SUCCESS',
  CHANGE_LINKS_TARGET_FAILURE: 'CHANGE_LINKS_TARGET_API_POST_FAILURE',
  CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE: 'CHANGE_LINKS_TARGET_SUCCESS_UNDOABLE',

  LINK_CONNECTABLES_REQUEST: 'LINK_CONNECTABLES_API_POST_REQUEST',
  LINK_CONNECTABLES_SUCCESS: 'LINK_CONNECTABLES_API_POST_SUCCESS',
  LINK_CONNECTABLES_FAILURE: 'LINK_CONNECTABLES_API_POST_FAILURE',
  LINK_CONNECTABLES_SUCCESS_UNDOABLE: 'LINK_CONNECTABLES_SUCCESS_UNDOABLE',

  DELETE_LINKS_REQUEST: 'DELETE_LINKS_API_POST_REQUEST',
  DELETE_LINKS_SUCCESS: 'DELETE_LINKS_API_POST_SUCCESS',
  DELETE_LINKS_FAILURE: 'DELETE_LINKS_API_POST_FAILURE',
  DELETE_LINKS_SUCCESS_UNDOABLE: 'DELETE_LINKS_SUCCESS_UNDOABLE',

  DELETE_CONNECTABLES_REQUEST: 'DELETE_CONNECTABLES_API_POST_REQUEST',
  DELETE_CONNECTABLES_SUCCESS: 'DELETE_CONNECTABLES_API_POST_SUCCESS',
  DELETE_CONNECTABLES_FAILURE: 'DELETE_CONNECTABLES_API_POST_FAILURE',

  MODIFY_PROJECT_VIDEO_ROOT_REQUEST: 'MODIFY_PROJECT_VIDEO_ROOT_API_POST_REQUEST',
  MODIFY_PROJECT_VIDEO_ROOT_SUCCESS: 'MODIFY_PROJECT_VIDEO_ROOT_API_POST_SUCCESS',
  MODIFY_PROJECT_VIDEO_ROOT_FAILURE: 'MODIFY_PROJECT_VIDEO_ROOT_API_POST_FAILURE',

  CHANGE_DK_FORKING_SOUNDTRACK_REQUEST: 'CHANGE_DK_FORKING_SOUNDTRACK_API_POST_REQUEST',
  CHANGE_DK_FORKING_SOUNDTRACK_SUCCESS: 'CHANGE_DK_FORKING_SOUNDTRACK_API_POST_SUCCESS',
  CHANGE_DK_FORKING_SOUNDTRACK_FAILURE: 'CHANGE_DK_FORKING_SOUNDTRACK_API_POST_FAILURE',

  SELECT_CONNECTABLES: 'SELECT_CONNECTABLES',
};
export default ACTIONS;
