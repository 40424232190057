import React, { useState } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  SkwrMenu,
  SkwrMenuItem,
  SkwrTooltip,
  ColorByUsage,
} from 'skewerui';
import UserSettingsDialog from '../dialogs/UserSettingsDialog';
import { ReactComponent as ShapeSVG } from '../../assets/svg/shape.svg';

const messages = defineMessages({
  userAccount: {
    id: 'connectionMenu.userAccount',
    defaultMessage: 'User Account',
  },
  logout: {
    id: 'connectionMenu.logout',
    defaultMessage: 'Logout',
  },
});

const useStyles = makeStyles(theme => ({
  ConnectionMenu: {
    flexGrow: 0,
    marginLeft: '10px',
    backgroundColor: 'transparent',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  userIcon: {
    height: '24px',
    width: '24px',
    border: 'none',
    outline: 'none',
    '& path': {
      fill: ColorByUsage.toolIconsForeground,
    },
  },
}));

const HeaderUserMenu = ({
  username,
  intl: { formatMessage },
  authenticationActions,
  onLogOut,
  appDedicatedUserMenuItems,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userSettingsDialogIsOpen, setUserSettingsDialogIsOpen] = useState(false);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function closeDropDown() {
    setAnchorEl(null);
  }

  function handleLogout() {
    authenticationActions.logout();
    if (onLogOut) {
      onLogOut();
    }
    closeDropDown();
  }

  function handleUserAccount() {
    showUserSettingsDialog();
    closeDropDown();
  }

  function showUserSettingsDialog() {
    setUserSettingsDialogIsOpen(true);
  }

  function hideUserSettingsDialog() {
    setUserSettingsDialogIsOpen(false);
  }

  return (
    <>
      <UserSettingsDialog
        isOpenProps={userSettingsDialogIsOpen}
        hide={hideUserSettingsDialog}
      />
      <SkwrTooltip title={username}>
        <button
          type="button"
          className={classNames(classes.ConnectionMenu)}
          onClick={handleClick}
        >
          <div className={classes.userIcon}>
            <ShapeSVG />
          </div>
        </button>
      </SkwrTooltip>
      <SkwrMenu
        id="ConnectionMenu-dropdown"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={closeDropDown}
      >
        { appDedicatedUserMenuItems && (
          appDedicatedUserMenuItems
        )}
        <SkwrMenuItem
          onClick={handleUserAccount}
        >
          {formatMessage(messages.userAccount)}
        </SkwrMenuItem>
        <SkwrMenuItem
          onClick={handleLogout}
        >
          {formatMessage(messages.logout)}
        </SkwrMenuItem>
      </SkwrMenu>
    </>
  );
};

export default injectIntl(HeaderUserMenu);
