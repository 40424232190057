import { isVideoLabel, isAudioLabel } from '../tools/LabelTools';
import { getCurrentProject } from './projectsSelectors';

/**
 * DATAKEYS STATE SELECTORS
 */

/**
 * Get the labels ARRAY of the current project
 * WARNING : returns all the labels EXCEPT THE AUDIO ONES !!
 *
 * @param {*} state The State
 */
export const getCurrentLabels = state => state.labels.labels;
export const getVideoLabels = (state) => {
  const labels = [];
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isVideoLabel(state.labels.labels[i], getCurrentProject(state))) {
      labels.push(state.labels.labels[i]);
    }
  }
  return labels;
};
export const getAudioLabels = (state) => {
  const labels = [];
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isAudioLabel(state.labels.labels[i], getCurrentProject(state))) {
      labels.push(state.labels.labels[i]);
    }
  }
  return labels;
};

/**
 * Get a SELECTOR that will allow to get a label given its IRI
 * @param {*} state The State
 */
export const getLabelFromIRISelector = (state) => {
  return (iri) => {
    const index = state.labels.labelsIndexesMap[iri];
    return state.labels.labels[index];
  };
};
export const getLabelFromIRI = (state, iri) => {
  const func = getLabelFromIRISelector(state);
  return func(iri);
};

/**
 * Get a SELECTOR that will allow to get a labels name given its IRIS
 * @param {*} state The State
 * @param {iris} ARRAY on label IRI
 */
export const getLabelsNameFromIRIsSelector = (state) => {
  return (iris) => {
    const labelsName = [];

    iris.forEach((iri) => {
      const label = getLabelFromIRI(state, iri);

      if (label && label.name) {
        labelsName.push(label.name);
      }
    });

    return labelsName;
  };
};

/**
 * Get the status of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsStatus = state => state.labels.status;

/**
 * Get the error of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsError = state => state.labels.error;


export const getLabelByName = (state, labelName) => {
  const func = getLabelByNameSelector(state);
  return func(labelName);
};

export const getLabelByNameSelector = (state) => {
  return (labelName) => {
    const label = state.labels.labels.find(lbl => lbl.name === labelName);

    return label;
  };
};
