/**
 * File ExportTools.jsx - Tools to manage Exports
 */
import dayjs from 'dayjs';
import { removeDiacritics } from 'skewerui';

/**
 * Get the dayjs Date containing the day of an export
 * NOT the exact hour ! Only the day -> for filter purpose
 * @param {*} exp The export we want the day of
 */
export const getExportCreationDay = (exp) => {
  let date = dayjs(exp.createdAt);
  date = date.set('hour', 11);
  date = date.set('minute', 0);
  date = date.set('second', 0);
  return date;
};

export const generateExportTechnicalName = (oldExportName) => {
  let exportName = oldExportName;

  exportName = removeDiacritics(exportName);

  exportName = exportName.replace(/^\s+|\s+$/g, '');

  // Make the string lowercase
  exportName = exportName.toLowerCase();

  const from = '·/_,:;';
  const to = '------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    exportName = exportName.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Remove invalid chars
  exportName = exportName.replace(/[^a-z0-9 -]/g, '')
  // Collapse whitespace and replace by -
    .replace(/\s+/g, '_')
  // Collapse dashes
    .replace(/-+/g, '_');

  return exportName;
};
