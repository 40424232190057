import * as DataKey from '../helpers/dataKey';
import CONSTANTS from '../Constants';
import { resetProjectSummary } from './projectsActions';
import ACTIONS from './dataKeysStateConstants';
import {
  getNewDkColor,
  doWeNeedToSetDefaultDataKeyValue,
  getValidTechnicalNameFromName,
  getValidTechnicalValueFromName,
} from '../tools/DataKeyTools';
import {
  getDkIRI,
  getDkvIRI,
  setDkIRI,
  setDkvIRI,
  getProjectIRI,
} from '../tools/IRITools';
import {
  getDataKeyFromIRI,
  getDataKeyValueFromIRI,
  getDataKeyFromDataKeyValueIRI,
} from './dataKeysSelectors';
import {
  getCurrentProject,
} from './projectsSelectors';

export const DATAKEYS_REVERT_ACTIONS = {
  // Create DataKey UNDO REDO parameters
  [ACTIONS.NEW_DATAKEY_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { dataKey }) => deleteDataKey(dataKey),
      createArgs: undoArgsForCreateDataKey,
      succeededWhen: [ACTIONS.DELETE_DATAKEY_SUCCESS],
      failedWhen: [ACTIONS.DELETE_DATAKEY_FAILURE],
    },
    redo: {
      command: (action, { name, color, dkvArray }) => createNewDataKey(name, color, dkvArray),
      createArgs: redoArgsForCreateDataKey,
      succeededWhen: [ACTIONS.NEW_DATAKEY_SUCCESS_UNDOABLE],
      failedWhen: [ACTIONS.NEW_DATAKEY_FAILURE],
      getParamsToPatch: getParamsToPatchForCreateDataKey,
    },
  },
  // Modify DataKey UNDO REDO parameters
  [ACTIONS.MODIFY_DATAKEY_SUCCESS]: {
    undo: {
      command: (action, { dataKey }) => modifyDataKey(dataKey),
      createArgs: undoArgsForModifyDataKey,
      succeededWhen: [ACTIONS.MODIFY_DATAKEY_SUCCESS],
      failedWhen: [ACTIONS.MODIFY_DATAKEY_FAILURE],
    },
    redo: {
      command: (action, { dataKey }) => modifyDataKey(dataKey),
      createArgs: redoArgsForModifyDataKey,
      failedWhen: [ACTIONS.MODIFY_DATAKEY_FAILURE],
    },
  },
  // Create DataKeyValue UNDO REDO parameters
  [ACTIONS.NEW_DATAKEYVALUE_SUCCESS_UNDOABLE]: {
    undo: {
      command: (action, { dataKeyValue, dataKey }) => deleteDataKeyValue(dataKeyValue, dataKey),
      createArgs: undoArgsForCreateDataKeyValue,
      succeededWhen: [ACTIONS.DELETE_DATAKEYVALUE_SUCCESS],
      failedWhen: [ACTIONS.DELETE_DATAKEYVALUE_FAILURE],
    },
    redo: {
      command: (action, { dataKey, dataKeyValue }) => recreateDataKeyValue(dataKeyValue, dataKey),
      createArgs: redoArgsForCreateDataKeyValue,
      succeededWhen: [ACTIONS.NEW_DATAKEYVALUE_SUCCESS],
      failedWhen: [ACTIONS.NEW_DATAKEYVALUE_FAILURE],
      getParamsToPatch: getParamsToPatchForCreateDataKeyValue,
    },
  },
  // Modify DataKeyValue UNDO REDO parameters
  [ACTIONS.MODIFY_DATAKEYVALUE_SUCCESS]: {
    undo: {
      command: (action, { dataKeyValue }) => modifyDataKeyValue(dataKeyValue),
      createArgs: undoArgsForModifyDataKeyValue,
      succeededWhen: [ACTIONS.MODIFY_DATAKEYVALUE_SUCCESS],
      failedWhen: [ACTIONS.MODIFY_DATAKEYVALUE_FAILURE],
    },
    redo: {
      command: (action, { dataKeyValue }) => modifyDataKeyValue(dataKeyValue),
      createArgs: redoArgsForModifyDataKeyValue,
      failedWhen: [ACTIONS.MODIFY_DATAKEYVALUE_FAILURE],
    },
  },
  // Delete DataKeyValue UNDO REDO parameters
  [ACTIONS.DELETE_DATAKEYVALUE_SUCCESS]: {
    undo: {
      command: (action, { dataKeyValue, dataKey }) => recreateDataKeyValue(dataKeyValue, dataKey),
      createArgs: undoArgsForDeleteDataKeyValue,
      succeededWhen: [ACTIONS.NEW_DATAKEYVALUE_SUCCESS],
      failedWhen: [ACTIONS.NEW_DATAKEYVALUE_FAILURE],
      getParamsToPatch: getParamsToPatchForDeleteDataKeyValue,
    },
    redo: {
      command: (action, { dataKeyValue, dataKey }) => deleteDataKeyValue(dataKeyValue, dataKey),
      createArgs: redoArgsForDeleteDataKeyValue,
      failedWhen: [ACTIONS.DELETE_DATAKEYVALUE_FAILURE],
    },
  },
};

export const DATAKEYS_UNREVERTABLE_ACTIONS = [
  // XXX quick and dirty fix for a crash issue (cf jira PROD-687)
  // XXX it should be better to code the undo
  ACTIONS.DELETE_DATAKEY_SUCCESS,
];


// XXX : updateDataKeys action unuse ???
/**
 * Update the dataKeys of the currentProject
 * by requesting the new array of dataKeys of the project
 */
export const updateDataKeys = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_DATAKEYS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_DATAKEYS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  DataKey.getDataKeys(currentProject.uuid).then(({ dataKeys }) => {
    dispatch({
      type: ACTIONS.GET_DATAKEYS_SUCCESS,
      project: currentProject,
      dataKeys,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_DATAKEYS_FAILURE,
      error: error.message,
    });
  });
};


/**
 * Action called to create a new dataKey
 * @param {string} dataKeyName new dataKey name
 */
export const createNewDataKey = (
  dataKeyName,
  dataKeyColor,
  dkvArray,
) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_DATAKEY_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_DATAKEY_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const projectIRI = getProjectIRI(currentProject);

  let dkColor = dataKeyColor;
  if ((typeof dataKeyColor === 'undefined') || (dataKeyColor === null)) {
    dkColor = getNewDkColor();
  }
  DataKey.createDataKey({
    name: dataKeyName,
    technicalName: getValidTechnicalNameFromName(dataKeyName),
    project: projectIRI,
    color: dkColor,
  }).then((createdDataKey) => {
    const newDataKey = DataKey.parseApiDataKey(createdDataKey);
    dispatch({
      type: ACTIONS.NEW_DATAKEY_SUCCESS,
      newDataKey,
    });

    // !!! IMPORTANT
    // The following loop is done instead of a Promise.call of a promises array
    // to ensure the order of the created dataKeyValues !!
    const createdDkvs = [];
    loopToCreateDkV(0);
    function loopToCreateDkV(i) {
      if (i < dkvArray.length) {
        new Promise((resolve, reject) => {
          dispatch(createNewDataKeyValue(
            newDataKey,
            false,
            null,
            dkvArray[i].name,
            dkvArray[i].color,
            resolve,
          ));
        }).then((data) => {
          createdDkvs.push(data);
          loopToCreateDkV(i + 1);
        }).catch((error) => {
          loopToCreateDkV(i + 1);
        });
      } else {
        // Last dataKeyValue was created, now we dispatch the undoable action!
        const undoAbleDataKey = { ...newDataKey };
        undoAbleDataKey.dataKeyValues = [...createdDkvs];
        dispatch({
          type: ACTIONS.NEW_DATAKEY_SUCCESS_UNDOABLE,
          newDataKey: undoAbleDataKey,
        });

        // Set the defaultDataKeyValue of the newly created dataKey
        dispatch(resetDefaultDataKeyValue(getDkIRI(newDataKey)));
      }
    }
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_DATAKEY_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of a datakey creation
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForCreateDataKey(state, action) {
  return {
    dataKey: action.newDataKey,
  };
}
/**
 * Creates the arguments for the redo command of a datakey modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForCreateDataKey(state, action) {
  let dkvArray = [];
  for (let i = 0; i < state.dataKeys.dataKeys.length; i += 1) {
    if (action.newDataKey.name === state.dataKeys.dataKeys[i].name) {
      dkvArray = state.dataKeys.dataKeys[i].dataKeyValues;
    }
  }
  return {
    name: action.newDataKey.name,
    color: action.newDataKey.color,
    dkvArray,
  };
}
/**
 * Retrieves the parameters that changed during the redo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForCreateDataKey(state, oldaction, newaction) {
  let paramstopatch = [];
  const {
    newDataKey: previousDataKey,
  } = oldaction;
  const {
    newDataKey,
  } = newaction;

  const previousDataKeyIRI = getDkIRI(previousDataKey);
  const newDataKeyIRI = getDkIRI(newDataKey);
  if (previousDataKeyIRI !== newDataKeyIRI) {
    const idParamtopatch = {
      field: 'id', // XXX - to be removed - one day
      type: CONSTANTS.TYPES.DATAKEY,
      previousvalue: previousDataKey.id,
      newvalue: newDataKey.id,
    };
    paramstopatch = [...paramstopatch, idParamtopatch];
    const atidParamtopatch = {
      field: CONSTANTS.IRI_FIELD,
      type: CONSTANTS.TYPES.DATAKEY,
      previousvalue: previousDataKeyIRI,
      newvalue: newDataKeyIRI,
    };
    paramstopatch = [...paramstopatch, atidParamtopatch];

    const iriParamtopatch = {
      field: 'dataKey',
      type: CONSTANTS.TYPES.DATAKEYVALUE,
      previousvalue: previousDataKeyIRI,
      newvalue: newDataKeyIRI,
    };
    paramstopatch = [...paramstopatch, iriParamtopatch];
  }

  if ((typeof previousDataKey.dataKeyValues !== 'undefined')
   && (previousDataKey.dataKeyValues !== null)
   && (previousDataKey.dataKeyValues.length === 1)
   && (typeof newDataKey.dataKeyValues !== 'undefined')
   && (newDataKey.dataKeyValues !== null)
   && (newDataKey.dataKeyValues.length === 1)) {
    const previousDkvIRI = getDkvIRI(previousDataKey.dataKeyValues[0]);
    const newDkvIRI = getDkvIRI(newDataKey.dataKeyValues[0]);
    if ((previousDkvIRI !== newDkvIRI)) {
      const paramtopatch = {
        field: 'id', // XXX - to be removed - one day
        type: CONSTANTS.TYPES.DATAKEYVALUE,
        previousvalue: previousDataKey.dataKeyValues[0].id,
        newvalue: newDataKey.dataKeyValues[0].id,
      };
      paramstopatch = [...paramstopatch, paramtopatch];
      const atidparamtopatch = {
        field: CONSTANTS.IRI_FIELD,
        type: CONSTANTS.TYPES.DATAKEYVALUE,
        previousvalue: previousDkvIRI,
        newvalue: newDkvIRI,
      };
      paramstopatch = [...paramstopatch, atidparamtopatch];
    }
  }

  return paramstopatch;
}// getParamsToPatchForCreateDataKey

/**
 * Action called to modify a dataKey object
 * @param {object} dataKey modified dataKeyValue object
 */
export const modifyDataKey = dataKey => (dispatch, getState) => {
  // Get the 'old' datakey before dispatching the REQUEST -> the request changes the state !!
  const dataKeyBeforeModification = getDataKeyFromIRI(getState(), getDkIRI(dataKey));

  dispatch({
    type: ACTIONS.MODIFY_DATAKEY_REQUEST,
    payload: 'waiting response from API ...',
    dataKeyToModify: dataKey,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEY_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
      dataKeyBeforeModification,
    });
    return;
  }

  // During an "undo" the dataKey is provided by the back from the result of the "do"
  // It contains a "project" field
  // We have to remove it to ensure that the API do not send a "NOT AN IRI" error
  const dataKeyToModify = { ...dataKey };
  if ((typeof dataKeyToModify.project !== 'undefined') && (dataKeyToModify.project !== null)) {
    delete dataKeyToModify.project;
  }

  // Do we need to set a defaultDataKeyValue ?
  const {
    needDefaultDkvReset,
    newDefaultDKV,
  } = doWeNeedToSetDefaultDataKeyValue(dataKeyBeforeModification);
  if (needDefaultDkvReset) {
    dataKeyToModify.defaultDataKeyValue = newDefaultDKV;
  }
  DataKey.modifyDataKey(dataKeyToModify).then((response) => {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEY_SUCCESS,
      dataKeyModified: DataKey.parseApiDataKey(response),
      dataKeyBeforeModification,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEY_FAILURE,
      error: error.message,
      dataKeyBeforeModification,
    });
  });
};

/**
 * Action called to set the default dataKeyValue on a dataKey
 * @param {object} dataKeyIRI
 */
export const resetDefaultDataKeyValue = (dataKeyIRI, deletedDkvIRI) => (dispatch, getState) => {
  // Get the 'old' datakey before dispatching the REQUEST -> the request changes the state !!
  const dataKeyBeforeModification = getDataKeyFromIRI(getState(), dataKeyIRI);

  // Do we need to set a defaultDataKeyValue ?
  const {
    needDefaultDkvReset,
    newDefaultDKV,
  } = doWeNeedToSetDefaultDataKeyValue(
    dataKeyBeforeModification,
    deletedDkvIRI,
  );

  if (!needDefaultDkvReset) {
    return;
  }

  const dataKeyToModify = { };
  setDkIRI(dataKeyToModify, dataKeyIRI);
  dataKeyToModify.defaultDataKeyValue = newDefaultDKV;

  dispatch({
    type: ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_REQUEST,
    payload: 'waiting response from API ...',
    dataKeyToModify,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
      dataKeyBeforeModification,
    });
    return;
  }

  // During an "undo" the dataKey is provided by the back from the result of the "do"
  // It contains a "project" field
  // We have to remove it to ensure that the API do not send a "NOT AN IRI" error
  if ((typeof dataKeyToModify.project !== 'undefined') && (dataKeyToModify.project !== null)) {
    delete dataKeyToModify.project;
  }

  DataKey.modifyDataKey(dataKeyToModify).then((response) => {
    dispatch({
      type: ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_SUCCESS,
      dataKeyModified: DataKey.parseApiDataKey(response),
      dataKeyBeforeModification,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_FAILURE,
      error: error.message,
      dataKeyBeforeModification,
    });
  });
}; // resetDefaultDataKeyValue

/**
 * Creates the arguments for the undo command of a datakey modification
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForModifyDataKey(state, action) {
  const modifiedDataKey = action.dataKeyModified;
  const previousDataKey = action.dataKeyBeforeModification;

  // Create the smaller as possible dataKey for the undo request
  const keys = Object.keys(previousDataKey);
  const dataKeyForUndo = {};
  setDkIRI(dataKeyForUndo, getDkIRI(previousDataKey));

  // Parse the object and keep only the different attributes
  keys.forEach((elem) => {
    if ((typeof modifiedDataKey[elem] !== 'undefined')
     && (modifiedDataKey[elem] !== null)
     && (modifiedDataKey[elem] !== previousDataKey[elem])
     && (elem !== 'dataKeyValues')) {
      dataKeyForUndo[elem] = previousDataKey[elem];
    }
  });

  return {
    dataKey: dataKeyForUndo,
  };
}
/**
 * Creates the arguments for the redo command of a datakey modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForModifyDataKey(state, action) {
  return {
    dataKey: action.dataKeyModified,
  };
}

/**
 * Action called to create a new dataKeyValue
 * @param {*} dataKey dataKey under which to create the new DataKeyValue
 * @param {*} undoable is this action undoable ? (false for automatic dataKeyValue creation)
 * @param {*} keyInstances keyInstances that need to be populated with new links
 *                         after the dataKeyValue is created
 */
export const createNewDataKeyValue = (
  dataKey,
  undoable,
  afterCreationCB,
  dkvName,
  dkvcolor,
  resolve,
) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_DATAKEYVALUE_REQUEST,
    payload: 'waiting response from API ...',
  });

  let dataKeyValueColor = '';
  if (dkvcolor) {
    dataKeyValueColor = dkvcolor;
  } else {
    dataKeyValueColor = DataKey.getDefaultDKVColor(dataKey);
  }

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  DataKey.createDataKeyValue({
    // Same as value / may change in future version
    name: dkvName,
    technicalValue: getValidTechnicalValueFromName(dkvName),
    dataKey: getDkIRI(dataKey),
    color: dataKeyValueColor,
  }).then((response) => {
    const createdDataKeyValue = DataKey.parseApiDataKey(response);
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_SUCCESS,
      newDataKeyValue: createdDataKeyValue,
      dataKey,
    });
    if (undoable) {
      dispatch({
        type: ACTIONS.NEW_DATAKEYVALUE_SUCCESS_UNDOABLE,
        newDataKeyValue: createdDataKeyValue,
        dataKey,
      });

      if (dataKey.defaultDataKeyValue === null) {
        dispatch(resetDefaultDataKeyValue(getDkIRI(dataKey)));
      }

      if ((typeof afterCreationCB !== 'undefined')
        && (afterCreationCB !== null)) {
        afterCreationCB(createdDataKeyValue);
      }
    }
    // Reset project summary
    dispatch(resetProjectSummary());
    if (resolve) { resolve(createdDataKeyValue); }
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Action called to create a new dataKeyValue
 * @param {string} dataKeyValue new dataKeyValue name
 */
export const recreateDataKeyValue = (dataKeyValue, dataKey) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_DATAKEYVALUE_REQUEST,
    payload: 'waiting response from API ...',
  });

  const dkvName = dataKeyValue.name;
  const dkvValue = dataKeyValue.technicalValue;
  const dataKeyValueColor = dataKeyValue.color;

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  DataKey.createDataKeyValue({
    name: dkvName,
    technicalValue: dkvValue,
    dataKey: getDkIRI(dataKey),
    color: dataKeyValueColor,
  }).then((response) => {
    const createdDataKeyValue = DataKey.parseApiDataKey(response);
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_SUCCESS,
      newDataKeyValue: createdDataKeyValue,
      dataKey,
    });
    // Reset project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_DATAKEYVALUE_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of a datakeyvalue creation
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForCreateDataKeyValue(state, action) {
  return {
    dataKeyValue: action.newDataKeyValue,
    dataKey: action.dataKey,
  };
}
/**
 * Creates the arguments for the redo command of a datakeyvalue creation
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForCreateDataKeyValue(state, action) {
  return {
    dataKey: action.dataKey,
    dataKeyValue: action.newDataKeyValue,
  };
}
/**
 * Retrieves the parameters that changed during the redo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForCreateDataKeyValue(state, oldaction, newaction) {
  let paramstopatch = [];
  const {
    newDataKeyValue: previousDataKeyValue,
  } = oldaction;
  const {
    newDataKeyValue,
  } = newaction;

  const previousDkvIRI = getDkvIRI(previousDataKeyValue);
  const newDkvIRI = getDkvIRI(newDataKeyValue);
  if ((previousDkvIRI !== newDkvIRI)) {
    const idParamtopatch = {
      field: 'id', // XXX - to be removed - one day
      type: CONSTANTS.TYPES.DATAKEYVALUE,
      previousvalue: previousDataKeyValue.id,
      newvalue: newDataKeyValue.id,
    };
    paramstopatch = [...paramstopatch, idParamtopatch];
    const atidParamtopatch = {
      field: CONSTANTS.IRI_FIELD,
      type: CONSTANTS.TYPES.DATAKEYVALUE,
      previousvalue: previousDkvIRI,
      newvalue: newDkvIRI,
    };
    paramstopatch = [...paramstopatch, atidParamtopatch];
  }

  return paramstopatch;
}// getParamsToPatchForCreateDataKeyValue

/**
 * Action called to modify a dataKeyValue object
 * @param {object} dataKeyValue modified dataKeyValue object
 */
export const modifyDataKeyValue = dataKeyValue => (dispatch, getState) => {
  // Get the 'old' datakeyvalue before dispatching the REQUEST -> the request changes the state !!
  const dataKeyValueBeforeModification = getDataKeyValueFromIRI(getState(), getDkIRI(dataKeyValue));
  const dataKeyToModify = getDataKeyFromDataKeyValueIRI(getState(), getDkvIRI(dataKeyValue));

  dispatch({
    type: ACTIONS.MODIFY_DATAKEYVALUE_REQUEST,
    dataKeyValueToModify: dataKeyValue,
    dataKeyIRI: getDkIRI(dataKeyToModify),
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEYVALUE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
      dataKeyValueBeforeModification,
      dataKeyIRI: getDkIRI(dataKeyToModify),
    });
    return;
  }

  // During an "undo" the dataKeyValue is provided by the back from the result of the "do"
  // It contains a "dataKeyValueType" field
  // We have to remove it to ensure that the API do not send a "USE AN IRI" error
  const dataKeyValueToModify = { ...dataKeyValue };
  if ((typeof dataKeyValueToModify.dataKeyValueType !== 'undefined')
    && (dataKeyValueToModify.dataKeyValueType !== null)) {
    delete dataKeyValueToModify.dataKeyValueType;
  }

  DataKey.modifyDataKeyValue(dataKeyValueToModify).then((response) => {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEYVALUE_SUCCESS,
      dataKeyValueModified: DataKey.parseApiDataKey(response),
      dataKeyValueBeforeModification,
      dataKeyIRI: getDkIRI(dataKeyToModify),
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.MODIFY_DATAKEYVALUE_FAILURE,
      error: error.message,
      dataKeyValueBeforeModification,
      dataKeyIRI: getDkIRI(dataKeyToModify),
    });
  });
};

/**
 * Creates the arguments for the undo command of a datakey modification
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForModifyDataKeyValue(state, action) {
  const modifiedDataKeyValue = action.dataKeyValueModified;
  const previousDataKeyValue = action.dataKeyValueBeforeModification;

  // Create the smaller as possible dataKeyValue for the undo request
  const keys = Object.keys(previousDataKeyValue);
  const dataKeyValueForUndo = {};
  setDkvIRI(dataKeyValueForUndo, getDkvIRI(previousDataKeyValue));

  // Parse the object and keep only the different attributes
  keys.forEach((elem) => {
    if ((typeof modifiedDataKeyValue[elem] !== 'undefined')
      && (modifiedDataKeyValue[elem] !== null)
      && (modifiedDataKeyValue[elem] !== previousDataKeyValue[elem])) {
      dataKeyValueForUndo[elem] = previousDataKeyValue[elem];
    }
  });

  return {
    dataKeyValue: dataKeyValueForUndo,
  };
}

/**
 * Creates the arguments for the redo command of a datakey modification
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForModifyDataKeyValue(state, action) {
  return {
    dataKeyValue: action.dataKeyValueModified,
  };
}

/**
 * Action called to delete a dataKeyValue
 * @param {string} dataKeyValue dataKeyValue to delete
 */
export const deleteDataKeyValue = (dataKeyValue, dataKey) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_DATAKEYVALUE_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_DATAKEYVALUE_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  DataKey.deleteDataKeyValue(dataKeyValue).then((response) => {
    dispatch({
      type: ACTIONS.DELETE_DATAKEYVALUE_SUCCESS,
      dataKeyValue,
      dataKey,
    });

    dispatch(resetDefaultDataKeyValue(getDkIRI(dataKey), getDkvIRI(dataKeyValue)));

    // reset project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_DATAKEYVALUE_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Creates the arguments for the undo command of a datakey deletion
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForDeleteDataKeyValue(state, action) {
  return {
    dataKeyValue: action.dataKeyValue,
    dataKey: action.dataKey,
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForDeleteDataKeyValue(state, oldaction, newaction) {
  let paramstopatch = [];
  const {
    dataKeyValue: previousDataKeyValue,
  } = oldaction;
  const {
    newDataKeyValue,
  } = newaction;

  const previousDkvIRI = getDkvIRI(previousDataKeyValue);
  const newDkvIRI = getDkvIRI(newDataKeyValue);
  if ((previousDkvIRI !== newDkvIRI)) {
    const idParamtopatch = {
      field: 'id', // XXX - to be removed - one day
      type: CONSTANTS.TYPES.DATAKEYVALUE,
      previousvalue: previousDataKeyValue.id,
      newvalue: newDataKeyValue.id,
    };
    paramstopatch = [...paramstopatch, idParamtopatch];
    const atidParamtopatch = {
      field: CONSTANTS.IRI_FIELD,
      type: CONSTANTS.TYPES.DATAKEYVALUE,
      previousvalue: previousDkvIRI,
      newvalue: newDkvIRI,
    };
    paramstopatch = [...paramstopatch, atidParamtopatch];
  }

  return paramstopatch;
}// getParamsToPatchForDeleteDataKeyValue

/**
 * Creates the arguments for the redo command of a datakey deletion
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForDeleteDataKeyValue(state, action) {
  return {
    dataKeyValue: action.dataKeyValue,
    dataKey: action.dataKey,
  };
}

export const deleteDataKey = dataKey => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_DATAKEY_REQUEST,
    payload: 'waiting response from API ...',
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_DATAKEY_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  DataKey.deleteDataKey(dataKey).then(() => {
    dispatch({
      type: ACTIONS.DELETE_DATAKEY_SUCCESS,
      dataKeyIRI: getDkIRI(dataKey),
    });
    // reset project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_DATAKEY_FAILURE,
      error: error.message,
    });
  });
};

// UI ACTIONS - modify graphical state

/**
 * Collapse or expand a DK in the DK Panel
 * @param {*} dkIRI to expand or collapse
 */
export const switchDkPanelExpandCollapseState = dkIRI => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.SWITCH_EXPAND_COLLAPSE_DK_PANEL_STATE,
    dkIRI,
    project: getCurrentProject(getState()),
  });
};
