import CONSTANTS from '../Constants';
import * as Exports from '../helpers/exports';
import { generateExportTechnicalName } from '../tools/ExportTools';
import PROJECTSACTIONS from './projectsStateConstants';

const initialState = {
  error: null,
  status: null,
  hasLoaded: false,
  exports: [],
  pageData: {},
};

export const ACTIONS = {
  CLEAR_ERROR: 'EXPORTS_CLEAR_ERROR',

  GET_EXPORTS_REQUEST: 'GET_EXPORTS_REQUEST_API_GET_REQUEST',
  GET_EXPORTS_SUCCESS: 'GET_EXPORTS_SUCCESS_API_GET_SUCCESS',
  GET_EXPORTS_FAILURE: 'GET_EXPORTS_FAILURE_API_GET_FAILURE',

  GET_PAGE_EXPORTS_REQUEST: 'GET_PAGE_EXPORTS_REQUEST_API_GET_REQUEST',
  GET_PAGE_EXPORTS_SUCCESS: 'GET_PAGE_EXPORTS_SUCCESS_API_GET_SUCCESS',
  GET_PAGE_EXPORTS_FAILURE: 'GET_PAGE_EXPORTS_FAILURE_API_GET_FAILURE',

  CREATE_EXPORTS_REQUEST: 'CREATE_EXPORTS_REQUEST_API_POST_REQUEST',
  CREATE_EXPORTS_SUCCESS: 'CREATE_EXPORTS_SUCCESS_API_POST_SUCCESS',
  CREATE_EXPORTS_FAILURE: 'CREATE_EXPORTS_FAILURE_API_POST_FAILURE',

  DELETE_EXPORTS_REQUEST: 'DELETE_EXPORTS_REQUEST_API_POST_REQUEST',
  DELETE_EXPORTS_SUCCESS: 'DELETE_EXPORTS_SUCCESS_API_POST_SUCCESS',
  DELETE_EXPORTS_FAILURE: 'DELETE_EXPORTS_FAILURE_API_POST_FAILURE',

  START_DOWNLOAD_REQUEST: 'START_DOWNLOAD_REQUEST',
  START_DOWNLOAD_SUCCESS: 'START_DOWNLOAD_SUCCESS',
  START_DOWNLOAD_FAILURE: 'START_DOWNLOAD_FAILURE',
};

export const EXPORTS_REVERT_ACTIONS = {
  // Delete DataKeyValue UNDO REDO parameters
  [ACTIONS.DELETE_EXPORTS_SUCCESS]: {
    undo: {
      command: (action, { name, replayToken }) => createExport(name, replayToken),
      createArgs: undoArgsForDeleteExport,
      succeededWhen: [ACTIONS.CREATE_EXPORTS_SUCCESS],
      failedWhen: [ACTIONS.CREATE_EXPORTS_FAILURE],
      getParamsToPatch: getParamsToPatchForDeleteExport,
    },
    redo: {
      command: (action, { exportIri }) => deleteExport(exportIri, () => {}),
      createArgs: redoArgsForDeleteExport,
      succeededWhen: [ACTIONS.DELETE_EXPORTS_SUCCESS],
      failedWhen: [ACTIONS.DELETE_EXPORTS_FAILURE],
    },
  },
};

/**
 * Creates the arguments for the undo command of a datakey deletion
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForDeleteExport(state, action) {
  const deletedExport = {};
  for (let i = 0; i < state.exports.exports.length; i += 1) {
    if (state.exports.exports[i][CONSTANTS.IRI_FIELD]
      === action.deletedExport[CONSTANTS.IRI_FIELD]) {
      deletedExport.name = state.exports.exports[i].name;
      deletedExport.replayToken = state.exports.exports[i].playlistGeneratorReplayToken;
    }
  }
  return {
    name: deletedExport.name,
    replayToken: deletedExport.replayToken,
  };
}

/**
 * Creates the arguments for the redo command of a datakey deletion
 * @param {*} state state when the action to redo was called
 * @param {*} action action to undo
 */
function redoArgsForDeleteExport(state, action) {
  return {
    exportIri: action.deletedExport,
  };
}

/**
 * Retrieves the parameters that changed during the undo command
 * and that needs to be patched in the redoQueue args
 * @param {*} state state when the action to redo was called
 * @param {*} oldaction action to re-do
 * @param {*} newaction action that was re-done
 */
function getParamsToPatchForDeleteExport(state, oldaction, newaction) {
  let paramstopatch = [];

  const oldIri = oldaction.deletedExport[CONSTANTS.IRI_FIELD];
  const newIri = newaction.response.data[CONSTANTS.IRI_FIELD];

  if (oldIri !== newIri) {
    const idParamtopatch = {
      field: CONSTANTS.IRI_FIELD,
      type: 'ExperienceExport',
      previousvalue: oldIri,
      newvalue: newIri,
    };
    paramstopatch = [...paramstopatch, idParamtopatch];
  }

  return paramstopatch;
}

/**
 * Update the eport of the currentProject
 * by requesting the new array of export of the project
 */
export const updateExports = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  Exports.getExports(currentProject.uuid).then((exports) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_SUCCESS,
      exports: exports.data['hydra:member'],
      pageData: {
        nextPageUrl: exports.data['hydra:view']['hydra:next'],
        totalExports: exports.data['hydra:totalItems'],
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_EXPORTS_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Increment the exports of the project
 * by requesting the new array of export of the project and add it
 */
export const loadNextPage = nextUrl => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_PAGE_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_PAGE_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  Exports.getNextPage(nextUrl).then((exports) => {
    const maxExportByPage = exports.data['hydra:view']['hydra:first'] ? exports.data['hydra:member'].length : -1;
    dispatch({
      type: ACTIONS.GET_PAGE_EXPORTS_SUCCESS,
      exports: exports.data['hydra:member'],
      pageData: {
        maxExportByPage,
        nextPageUrl: exports.data['hydra:view']['hydra:next'],
        totalExports: exports.data['hydra:totalItems'],
      },
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.GET_PAGE_EXPORTS_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Add new export to th project by requesting API with new export name
 */
export const createExport = (name, replayToken) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.CREATE_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.CREATE_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const technicalName = generateExportTechnicalName(name);

  Exports.createExports(name, technicalName, replayToken, currentProject.uuid)
    .then((response) => {
      dispatch({
        type: ACTIONS.CREATE_EXPORTS_SUCCESS,
        status: ACTIONS.CREATE_EXPORTS_SUCCESS,
        response,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.CREATE_EXPORTS_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Delete export to th project by requesting API with export iri
 */
export const deleteExport = (exportIri, resolvePromise) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_EXPORTS_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.DELETE_EXPORTS_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }


  Exports.deleteExports(exportIri[CONSTANTS.IRI_FIELD])
    .then((response) => {
      dispatch({
        type: ACTIONS.DELETE_EXPORTS_SUCCESS,
        status: ACTIONS.DELETE_EXPORTS_SUCCESS,
        deletedExport: exportIri,
      });
      resolvePromise();
    }).catch((error) => {
      dispatch({
        type: ACTIONS.DELETE_EXPORTS_FAILURE,
        error: error.message,
      });
      resolvePromise();
    });
};

/**
 * Start the download of a given uuid of export
 */
export const startDownload = exportIri => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.START_DOWNLOAD_REQUEST,
    payload: 'waiting response from API ...',
  });

  const { currentProject } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.START_DOWNLOAD_FAILURE,
      error: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }


  Exports.getDownloadUrl(exportIri)
    .then((response) => {
      window.open(response.url, '_self'); // download the content uf url

      dispatch({
        type: ACTIONS.START_DOWNLOAD_SUCCESS,
        status: ACTIONS.START_DOWNLOAD_SUCCESS,
        iri: exportIri,
        url: response.url,
        expireAt: response.expires_at,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.START_DOWNLOAD_FAILURE,
        error: error.message,
      });
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };
  
    case PROJECTSACTIONS.GET_PROJECT_REQUEST:
      return {
        ...initialState,
      };

    case ACTIONS.GET_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_REQUEST,
      };

    case ACTIONS.GET_EXPORTS_SUCCESS:
      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_SUCCESS,
        exports: action.exports,
        pageData: action.pageData,
        totalExports: action.totalExports,
        hasLoaded: true,
      };

    case ACTIONS.GET_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_EXPORTS_FAILURE,
        error: action.error,
      };

    case ACTIONS.GET_PAGE_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_PAGE_EXPORTS_REQUEST,
      };

    case ACTIONS.GET_PAGE_EXPORTS_SUCCESS:
    {
      const newExportsArray = [...state.exports];

      for (let i = 0; i < action.exports.length; i += 1) {
        newExportsArray.push({ ...action.exports[i] });
      }

      return {
        ...state,
        status: ACTIONS.GET_PAGE_EXPORTS_SUCCESS,
        exports: newExportsArray,
        pageData: action.pageData,
      };
    }

    case ACTIONS.GET_PAGE_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.GET_PAGE_EXPORTS_FAILURE,
        error: action.error,
      };

    case ACTIONS.CREATE_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_REQUEST,
      };

    case ACTIONS.CREATE_EXPORTS_SUCCESS:
    {
      const newExportsArray = [...state.exports];
      newExportsArray.push(action.response.data);

      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_SUCCESS,
        exports: newExportsArray,
      };
    }

    case ACTIONS.CREATE_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.CREATE_EXPORTS_FAILURE,
        error: action.error,
      };
    case ACTIONS.DELETE_EXPORTS_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_REQUEST,
      };
    case ACTIONS.DELETE_EXPORTS_SUCCESS:
    {
      const newExportsArray = [...state.exports];
      for (let i = 0; i < newExportsArray.length; i += 1) {
        if (newExportsArray[i][CONSTANTS.IRI_FIELD] === action.deletedExport[CONSTANTS.IRI_FIELD]) {
          newExportsArray.splice(i, 1);
        }
      }

      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_SUCCESS,
        exports: newExportsArray,
      };
    }
    case ACTIONS.DELETE_EXPORTS_FAILURE:
      return {
        ...state,
        status: ACTIONS.DELETE_EXPORTS_FAILURE,
        error: action.error,
      };

    case ACTIONS.START_DOWNLOAD_REQUEST:
      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_REQUEST,
      };
    case ACTIONS.START_DOWNLOAD_SUCCESS:
    {
      const downloadData = {
        url: action.url,
        expireAt: action.expireAt,
      };
      const newExportsArray = [...state.exports];
      for (let i = 0; i < state.exports.length; i += 1) {
        if (newExportsArray[i][CONSTANTS.IRI_FIELD] === action.iri) {
          newExportsArray[i].downloadData = { ...downloadData };
        }
      }

      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_SUCCESS,
        exports: newExportsArray,
      };
    }
    case ACTIONS.START_DOWNLOAD_FAILURE:
      return {
        ...state,
        status: ACTIONS.START_DOWNLOAD_FAILURE,
        error: action.error,
      };

    default:
      return state;
  }
};
