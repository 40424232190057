/**
 * File : mediaLibraryActions.js
 * Actions and state to manage assets
 */
import * as MediaLibrary from '../helpers/mediaLibrary';
import * as Labels from '../helpers/labels';
import * as Nodes from '../helpers/nodes';
import CONSTANTS, { ASSET, UPLOAD_STATUS } from '../Constants';
import PENDING_REQUESTS_ACTIONS from './pendingRequestsStateConstants';
import ACTIONS from './mediaLibraryStateConstants';
import LABELSACTIONS from './labelsStateConstants';
import NODESACTIONS from './nodesStateConstants';
import { resetProjectSummary } from './projectsActions';
import { deleteLabels } from './labelsState';
import {
  getAssetFromIRI,
  getAssetDedicatedLabel,
} from './mediaLibrarySelector';
import { getFileToUploadFromIndex } from './mediaLibraryState';
import {
  isCurrentProject,
} from './projectsSelectors';
import {
  getAudioMediaTypeIRI,
  getAssetIRI,
  setAssetIRI,
  getLabelIRI,
  getProjectIRI,
} from '../tools/IRITools';
import {
  isAudioAsset,
  ASSET_PREVIEW_STATUS_NAME_READY,
} from '../tools/AssetTools';

import { getConnectableFromIRI } from './nodesSelectors';


export const ASSETS_REVERT_ACTIONS = {
  [ACTIONS.UPDATE_ASSET_NAME_SUCCESS]: {
    undo: {
      command: (action, { asset, name }) => changeAssetName(asset, name),
      createArgs: undoArgsForUpdateAssetName,
      succeededWhen: [ACTIONS.UPDATE_ASSET_NAME_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_ASSET_FAILURE],
    },
    redo: {
      command: (action, { asset, name }) => changeAssetName(asset, name),
      createArgs: redoArgsForUpdateAssetName,
      succeededWhen: [ACTIONS.UPDATE_ASSET_NAME_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_ASSET_FAILURE],
    },
  },
  [ACTIONS.UPDATE_ASSETS_LABELS_SUCCESS]: {
    undo: {
      command: (action, { changes }) => changeAssetsLabels(changes),
      createArgs: undoArgsForUpdateAssetsLabels,
      succeededWhen: [ACTIONS.UPDATE_ASSETS_LABELS_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_ASSETS_LABELS_FAILURE],
    },
    redo: {
      command: (action, { changes }) => changeAssetsLabels(changes),
      createArgs: redoArgsForUpdateAssetsLabels,
      succeededWhen: [ACTIONS.UPDATE_ASSETS_LABELS_SUCCESS],
      failedWhen: [ACTIONS.UPDATE_ASSETS_LABELS_FAILURE],
    },
  },
};

export const ASSETS_UNREVERTABLE_ACTIONS = [
  ACTIONS.DELETE_ASSETS_SUCCESS,
];

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};


/**
 * Change the labels of a given array of assets
 * @param {*} changesArray array of data needed to update the labels of assets
 *   ->  one element per asset
 *   {
 *     asset : asset to update -> contains only the array of new wanted labels
 *     previousLabels : array of old labels of this asset
 *   }
 */
export const changeAssetsLabels = changesArray => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  const assetsToRestoreInCaseOfFailure = [];
  const dataToChange = [];
  changesArray.forEach((changeData) => {
    const fullAsset = getAssetFromIRI(getState(), getAssetIRI(changeData.asset));
    assetsToRestoreInCaseOfFailure.push({
      ...fullAsset,
    });
    dataToChange.push({
      asset: {
        ...fullAsset,
        labels: changeData.asset.labels,
      },
      previousLabels: changeData.previousLabels,
    });
  });

  dispatch({
    type: ACTIONS.UPDATE_ASSETS_LABELS_REQUEST,
    changedData: dataToChange,
  });

  const {
    promises,
  } = getChangeAssetsLabelsPromises(changesArray);

  return Promise.all(promises).then((responseArray) => {
    const changedData = [];
    responseArray.forEach((asset) => {
      let prevLabels = [];
      const foundData = changesArray.find((change) => {
        return (getAssetIRI(change.asset) === getAssetIRI(asset));
      });
      if (foundData) {
        prevLabels = foundData.previousLabels;
      }
      changedData.push({
        asset,
        previousLabels: prevLabels,
      });
    });
    dispatch({
      type: ACTIONS.UPDATE_ASSETS_LABELS_SUCCESS,
      changedData,
    });

    // update project summary
    dispatch(resetProjectSummary());

    return responseArray;
  }).catch((error) => {
    dispatch({
      type: ACTIONS.UPDATE_ASSETS_LABELS_FAILURE,
      assets: assetsToRestoreInCaseOfFailure,
      error: error.message,
    });

    return error;
  });
};

function getChangeAssetsLabelsPromises(changesArray) {
  const promises = [];

  changesArray.forEach((changeData) => {
    promises.push(MediaLibrary.updateAsset(changeData.asset, changeData.asset));
  });

  return {
    promises,
  };
}// getChangeAssetsLabelsPromises


/**
 * Creates the arguments for the undo command of a labels change on an array of assets
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForUpdateAssetsLabels(state, action) {
  const changesArray = [];
  action.changedData.forEach((change) => {
    const assetModified = {
      labels: [...change.previousLabels],
    };
    setAssetIRI(assetModified, getAssetIRI(change.asset));
    changesArray.push({
      asset: assetModified,
      previousLabels: [...change.asset.labels],
    });
  });

  return {
    changes: changesArray,
  };
}// undoArgsForUpdateAssetsLabels

/**
 * Creates the arguments for the redo command of a labels change on an array of assets
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForUpdateAssetsLabels(state, action) {
  const changesArray = [];
  action.changedData.forEach((change) => {
    const assetModified = {
      labels: [...change.asset.labels],
    };
    setAssetIRI(assetModified, getAssetIRI(change.asset));
    changesArray.push({
      asset: assetModified,
      previousLabels: [...change.previousLabels],
    });
  });

  return {
    changes: changesArray,
  };
}// redoArgsForUpdateAssetsLabels

/**
 * Change the asset name
 * @param {*} node Asset of which we will change the label
 * @param {*} name the new asset name
 */
export const changeAssetName = (asset, name) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.UPDATE_ASSET_REQUEST,
    payload: 'waiting response from API ...',
  });

  MediaLibrary.updateAsset(asset, { name })
    .then((updatedAsset) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_SUCCESS,
        asset: updatedAsset,
      });
      dispatch({
        type: ACTIONS.UPDATE_ASSET_NAME_SUCCESS,
        asset,
        oldName: asset.name,
        newName: updatedAsset.name,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Creates the arguments for the undo command of a name change on an asset
 * @param {*} state state when the action to undo was called
 * @param {*} action action to undo
 */
function undoArgsForUpdateAssetName(state, action) {
  return {
    asset: { ...action.asset },
    name: action.oldName,
  };
}// undoArgsForUpdateAssetName

/**
 * Creates the arguments for the redo command of a name change on an asset
 * @param {*} state state when the action to redo was called
 * @param {*} action action to redo
 */
function redoArgsForUpdateAssetName(state, action) {
  return {
    asset: { ...action.asset },
    name: action.newName,
  };
}


export const retrieveAssets = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ACTIONS.GET_ASSETS_REQUEST,
    });

    const {
      currentProject,
    } = getState().projects;

    if ((currentProject === null) || (typeof currentProject === 'undefined')) {
      dispatch({
        type: ACTIONS.GET_ASSETS_FAILURE,
        payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
      });
      return;
    }

    MediaLibrary.getAssets(currentProject.uuid)
      .then((assetsData) => {
        if (isCurrentProject(getState(), currentProject.uuid)) {
          dispatch({
            type: ACTIONS.GET_ASSETS_SUCCESS,
            assetsData,
          });
        }
      }).catch((error) => {
        dispatch({
          type: ACTIONS.GET_ASSETS_FAILURE,
          error: error.message,
        });
      });
  };
};

/**
 * Get asset from assetIRI
 * @param {*} assetUuid assetIRI
 */
export const getAsset = assetIRI => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.GET_ASSET_REQUEST,
  });

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.GET_ASSET_FAILURE,
      payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
  }

  MediaLibrary.getAsset(assetIRI)
    .then((asset) => {
      dispatch({
        type: ACTIONS.GET_ASSET_SUCCESS,
        asset,
      });
    }).catch((error) => {
      dispatch({
        type: ACTIONS.GET_ASSET_FAILURE,
        error: error.message,
      });
    });
};

/**
 * Uploads an array of assets
 * @param {*} assetsToUpload array of assets to upload
 * @param {array} labels if not null array of label IRIs to set on newly created assets
 *                       They should be compatible with the kind (mediaType) of assets
 * @param {string} storyBlockIRI storyblock to which should be associated the assets that
 *                               will be created given either their own dedicated label
 *                               or with the given above labels
 */
export const addAssetsToUploadQueue = (
  assetsToUpload,
  labels,
  nodeIRI,
  endOfCreationCallback,
) => (dispatch, getState) => {
  // If there is no file given, DO NOTHING !
  if ((typeof assetsToUpload === 'undefined')
   || (assetsToUpload === null)
   || (assetsToUpload.length === 0)) {
    console.error('There is no file to upload !');
    return;
  }

  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_ASSET_FAILURE,
      payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const {
    creationProgress,
  } = getState().mediaLibrary;

  // Check if the asset creation loop is already running or not
  let isAlreadyRunning = false;
  if ((creationProgress.toCreate.length !== 0)
   || (creationProgress.creationInProgress.length !== 0)) {
    isAlreadyRunning = true;
  }

  dispatch({
    type: ACTIONS.ADD_FILES_TO_CREATION_QUEUE,
    files: assetsToUpload,
    labels,
    nodeIRI,
    endOfCreationCallback,
  });

  // If the loop was not already running, start it !
  if (!isAlreadyRunning) {
    // dispatch a global post request to ensure that the user
    // will not be able to quit between to creation or upload actions
    dispatch({
      type: PENDING_REQUESTS_ACTIONS.ADD_POST_REQUEST,
    });

    dispatch(loopToCreateAssets());
  }
};// addAssetsToUploadQueue

/**
 * Loop to create Assets
 * Creates firt asset in the creation pending queue
 */
export const loopToCreateAssets = () => (dispatch, getState) => {
  const {
    creationProgress,
    uploadProgress,
  } = getState().mediaLibrary;
  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_ASSET_FAILURE,
      payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  const projectIRI = getProjectIRI(currentProject);

  // Check if we are at the end of the creation queue
  if (creationProgress.toCreate.length === 0) {
    // RAF NOTHING TO DO ANYMORE ?!
    // DISPATCH END OF THE LOOP EVENT !!
    dispatch({
      type: ACTIONS.END_ASSET_CREATION_LOOP,
    });
    return;
  }

  createAssetForFile(creationProgress.toCreate[0]);

  function createAssetForFile(currentFileData) {
    const currentFileIdx = currentFileData.idxInUpload;
    const currentFile = getFileToUploadFromIndex(currentFileIdx);
    if (currentFile === null) {
      console.debug('createAssetForFile : Unexpected error : file index out of range');
      return;
    }

    // WARNING !!
    // On react-dropzone page :
    // Warning: On most recent browsers versions, the files given by onDrop won't have
    // properties path or fullPath
    // For security reasons (sic.)
    if (typeof currentFile.path !== 'undefined') {
      console.log('Try to create asset for file :  ', currentFile.path);
    } else {
      console.log('Try to create asset for file :  ', currentFile);
    }

    dispatch({
      type: ACTIONS.START_ASSET_CREATION,
      fileIdxInUploadArray: currentFileIdx,
    });

    dispatch({
      type: ACTIONS.NEW_ASSET_REQUEST,
    });

    const assetToCreate = {
      project: projectIRI,
      name: currentFile.name,
      sourcePath: currentFile.name, // TODO - remove this field once fileName exists on back side
      fileName: currentFile.name,
    };

    if ((typeof currentFileData.labels !== 'undefined')
     && (currentFileData.labels !== null)
     && (currentFileData.labels.length !== 0)) {
      assetToCreate.labels = [...currentFileData.labels];
    }

    // create
    MediaLibrary.createAsset(assetToCreate).then((newlyCreatedAsset) => {
      if (isCurrentProject(getState(), currentProject.uuid)) {
        dispatch({
          type: ACTIONS.NEW_ASSET_SUCCESS,
          payload: newlyCreatedAsset,
        });

        dispatch({
          type: ACTIONS.END_ASSET_CREATION,
          fileIdxInUploadArray: currentFileIdx,
          asset: newlyCreatedAsset,
        });

        if (isAudioAsset(newlyCreatedAsset, currentProject)) {
          // Audio Asset

          Labels.createLabel({
            name: getAssetIRI(newlyCreatedAsset),
            project: projectIRI,
            mediaType: getAudioMediaTypeIRI(currentProject),
            assets: [getAssetIRI(newlyCreatedAsset)],
          }).then((label) => {
            dispatch({
              type: LABELSACTIONS.NEW_LABEL_SUCCESS,
              newLabel: label,
            });

            let endOfCreationDiffered = false;
            if ((typeof currentFileData.nodeIRI !== 'undefined')
             && (currentFileData.nodeIRI !== null)) {
              const node = getConnectableFromIRI(getState(), currentFileData.nodeIRI);
              if (node) {
                const nodeToModify = {
                  id: node.id,
                  label: getLabelIRI(label),
                };

                endOfCreationDiffered = true;

                Nodes.modifyConnectable(CONSTANTS.TYPES.NODE, nodeToModify)
                  .then((modifiedConnectable) => {
                    dispatch({
                      type: NODESACTIONS.CHANGE_NODE_LABEL_SUCCESS,
                      connectable: modifiedConnectable,
                    });

                    // After 'full creation' of the asset - it means when all the extra
                    // params are created
                    if ((typeof currentFileData.endOfCreationCallback !== 'undefined')
                    && (currentFileData.endOfCreationCallback !== null)) {
                      currentFileData.endOfCreationCallback(label);
                    }
                  }).catch((error) => {
                    // Something went wrong but we don't care, the user can still associate the
                    // soundtrack to the node manually !After 'full creation' of the asset
                    // it means when all the extra params are created
                    if ((typeof currentFileData.endOfCreationCallback !== 'undefined')
                    && (currentFileData.endOfCreationCallback !== null)) {
                      currentFileData.endOfCreationCallback(null);
                    }
                  });
              }
            }
            if (!endOfCreationDiffered) {
              // After 'full creation' of the asset - it means when all the extra params are created
              if ((typeof currentFileData.endOfCreationCallback !== 'undefined')
              && (currentFileData.endOfCreationCallback !== null)) {
                currentFileData.endOfCreationCallback(label);
              }
            }
          }).catch((error) => {
            console.error(`Unable to create label associated to audio asset : ${error}`);
          });
        } else if ((typeof currentFileData.endOfCreationCallback !== 'undefined')
           && (currentFileData.endOfCreationCallback !== null)) {
          currentFileData.endOfCreationCallback(null);
        }

        dispatch(loopToCreateAssets());

        // Should we start the upload loop ?
        let isUploadAlreadyRunning = false;
        if (uploadProgress.uploadInProgress.length !== 0) {
          isUploadAlreadyRunning = true;
        }
        if (!isUploadAlreadyRunning) {
          // Start the upload loop
          dispatch(loopToUploadAssets());
        }
      }
    }).catch((error) => {
      if (isCurrentProject(getState(), currentProject.uuid)) {
        dispatch({
          type: ACTIONS.NEW_ASSET_FAILURE,
          error,
        });

        dispatch({
          type: ACTIONS.END_ASSET_CREATION,
          fileIdxInUploadArray: currentFileIdx,
          asset: null,
        });

        dispatch(loopToCreateAssets());

        // Should we start the upload loop ?
        let isUploadAlreadyRunning = false;
        if (uploadProgress.uploadInProgress.length !== 0) {
          isUploadAlreadyRunning = true;
        }
        if (!isUploadAlreadyRunning) {
          // Start the upload loop
          dispatch(loopToUploadAssets());
        }
      }
    });
  } // createAssetForFile
};// loopToCreateAssets

/**
 * Loop to upload Assets
 * Upload firt asset in the upload pending queue
 */
export const loopToUploadAssets = () => (dispatch, getState) => {
  const {
    uploadProgress,
    creationProgress,
  } = getState().mediaLibrary;
  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_ASSET_FAILURE,
      payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  // Check if we are at the end of the upload queue
  if (uploadProgress.toUpload.length === 0) {
    // RAF NOTHING TO DO ANYMORE ?!
    if ((creationProgress.toCreate.length !== 0)
     || (creationProgress.creationInProgress.length !== 0)) {
      // This case can arrive if the upload loop goes quicker
      // than the creation loop
      console.debug('End of upload loop, but creation still in progress');
    } else {
      console.debug('End of creation and upload loops');

      // DISPATCH END OF THE LOOP EVENT !!
      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD_LOOP,
      });
      dispatch({
        type: PENDING_REQUESTS_ACTIONS.REMOVE_POST_REQUEST,
      });
    }

    return;
  }

  uploadAssetFile(
    uploadProgress.toUpload[0].fileIdxInUploadArray,
    uploadProgress.toUpload[0].asset,
  );

  function uploadAssetFile(fileToUploadIdx, newlyCreatedAsset) {
    const fileToUpload = getFileToUploadFromIndex(fileToUploadIdx);
    if (fileToUpload === null) {
      console.debug('uploadAssetFile : Unexpected error : file index out of range');
      return;
    }

    dispatch({
      type: ACTIONS.START_ASSET_UPLOAD,
      assetFile: {
        fileIdxInUploadArray: fileToUploadIdx,
        asset: newlyCreatedAsset,
      },
    });

    if (newlyCreatedAsset === null) {
      // Case when an error occured during the creation of the asset
      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // OK upload fails, but what ?! Continue to upload the other files
      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());

      return;
    }


    // Upload Asset
    if (typeof fileToUpload.path !== 'undefined') {
      console.log('Try to upload file :  ', fileToUpload.path);
    } else {
      console.log('Try to upload file :  ', fileToUpload);
    }
    dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.START));
    MediaLibrary.uploadAsset(newlyCreatedAsset, fileToUpload).then((data) => {
      dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.SUCCESS));

      // Is the transcode loop running ?
      const {
        transcodeProgress,
      } = getState().mediaLibrary;
      const transcodeAllreadyRunning = (transcodeProgress.transcodeInProgress.length !== 0);

      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());

      // start a loop to wait for preview transcodings
      if (!transcodeAllreadyRunning) {
        dispatch(loopToWaitForTranscodedAssets());
      }
    }).catch((error) => {
      dispatch(changeAssetUploadStatus(newlyCreatedAsset, UPLOAD_STATUS.ERROR));

      console.error(`Unable to upload file '${fileToUpload.name}' : ${error}`);

      dispatch({
        type: ACTIONS.END_ASSET_UPLOAD,
        assetFile: {
          fileIdxInUploadArray: fileToUploadIdx,
          asset: newlyCreatedAsset,
        },
      });

      // OK upload fails, but what ?! Continue to upload the other files
      // Continue the upload loop - Upload next asset file
      dispatch(loopToUploadAssets());
    });
  } // uploadAssetFiles
};// loopToUploadAssets

export const loopToWaitForTranscodedAssets = () => (dispatch, getState) => {
  const {
    transcodeProgress,
  } = getState().mediaLibrary;
  const {
    currentProject,
  } = getState().projects;

  if ((currentProject === null) || (typeof currentProject === 'undefined')) {
    dispatch({
      type: ACTIONS.NEW_ASSET_FAILURE,
      payload: CONSTANTS.COMMONERRORS.NO_CURRENT_PROJECT,
    });
    return;
  }

  if (transcodeProgress.transcodeInProgress.length === 0) {
    // no need to wait anymore
    return;
  }

  // get the creationDate of first asset in the waiting array
  let waitingAsset;
  for (let i = 0;
    (i < transcodeProgress.transcodeInProgress.length) && (typeof waitingAsset === 'undefined');
    i += 1) {
    waitingAsset = getAssetFromIRI(getState(), transcodeProgress.transcodeInProgress[i]);
  }

  if (typeof waitingAsset === 'undefined') {
    // no need to wait anymore - may be the assets are already deleted
    return;
  }

  dispatch({
    type: ACTIONS.GET_ASSETS_REQUEST,
  });

  const filterParams = {};
  filterParams['createdAt[after]'] = waitingAsset.createdAt;
  filterParams['preview.status.name'] = ASSET_PREVIEW_STATUS_NAME_READY;

  MediaLibrary.getAssets(currentProject.uuid, filterParams)
    .then((assetsData) => {
      if (isCurrentProject(getState(), currentProject.uuid)) {
        dispatch({
          type: ACTIONS.GET_ASSETS_SUCCESS,
          assetsData,
        });

        // Loop every n seconds
        // set a timeout to prevent server to feel attacked by too much requests
        setTimeout(() => { dispatch(loopToWaitForTranscodedAssets()); }, 1500);
      }
    }).catch((error) => {
      dispatch({
        type: ACTIONS.GET_ASSETS_FAILURE,
        error: error.message,
      });
    });
}; // loopToWaitForTranscodedAssets


const changeAssetUploadStatus = (asset, uploadStatus) => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.UPDATE_ASSET_REQUEST,
    payload: 'waiting response from API ...',
  });

  const modifiedAsset = {
    cloudResourceUploadStatus: uploadStatus,
  };

  MediaLibrary.updateAsset(asset, modifiedAsset)
    .then((assetData) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_SUCCESS,
        asset: assetData,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTIONS.UPDATE_ASSET_FAILURE,
        error: error.message,
      });
      dispatch(getAsset(getAssetIRI(asset)));
    });
};


/**
 * Delete an array of assets
 */
export const deleteAssets = assets => (dispatch, getState) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.DELETE_ASSETS_REQUEST,
    assets,
  });

  // There are may be some labels to delete - case of automatically created labels
  const labelsToDelete = getAssetLabelsToDelete(assets, getState());

  const {
    promises,
  } = getDeleteAssetsPromises(assets);

  Promise.all(promises).then((response) => {
    dispatch({
      type: ACTIONS.DELETE_ASSETS_SUCCESS,
      deletedAssets: assets,
    });

    if ((typeof labelsToDelete !== 'undefined')
     && (labelsToDelete !== null)
     && (labelsToDelete.length !== 0)) {
      dispatch(deleteLabels(labelsToDelete));
    }

    // update project summary
    dispatch(resetProjectSummary());
  }).catch((error) => {
    dispatch({
      type: ACTIONS.DELETE_ASSETS_FAILURE,
      error: error.message,
    });
  });
};

function getDeleteAssetsPromises(assets) {
  const promises = [];

  assets.forEach((asset) => {
    promises.push(MediaLibrary.deleteAsset(asset));
  });

  return {
    promises,
  };
}// getDeleteAssetsPromises

/**
 * Returns the list of labels to delete while deleting assets
 * @param {*} assets array of assets to delete
 */
const getAssetLabelsToDelete = (assets, state) => {
  const labelsToDelete = [];
  assets.forEach((asset) => {
    const label = getAssetDedicatedLabel(state, asset);
    if (label !== null) {
      labelsToDelete.push(label);
    }
  });

  return labelsToDelete;
};
