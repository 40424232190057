import { isKeyInstance } from '../tools/ConnectableTools';

const SIZES = {
  KEY_INSTANCE: { width: 25, height: 25 },
  NODES: { width: 120, height: 100 },
  MARGIN: { width: 30, height: 15 },
  DATAKEYVALUE: { width: 25, height: 25 },
};


export const SCALE_LEVELS = {
  min: {
    value: 0.1,
    cssTransform: 0.1,
  },
  max: {
    value: 10,
    cssTransform: 3,
  },
};


const USERACTIONS = {
  ADD: 'add',
  FORK: 'fork',
  DELETE: 'delete',
  DELETE_KEEP_LINKS: 'shunt',
  SET_ROOT: 'setRoot',
  PERSONNALIZE: 'personnalize',
  ADD_KEYINSTANCE_ANSWER: 'addAnswer',
  LINK: 'link',
  UNLINK: 'unlink',
};

export { SIZES, USERACTIONS };

/**
 * Returns the size of a connectable in the graph
 * @param {*} con connectable we want the size
 */
export const getConnectableSize = (con) => {
  if ((typeof con !== 'undefined')
   && (con !== null)
   && (isKeyInstance(con))) {
    return SIZES.KEY_INSTANCE;
  }
  return SIZES.NODES;
};
