/**
 * File : mediaLibraryState.js
 * State to manage assets
 */
import { isUploadPending } from '../Constants';
import ACTIONS from './mediaLibraryStateConstants';
import PROJECTSACTIONS from './projectsStateConstants';
import LABELSACTIONS from './labelsStateConstants';
import {
  getAssetIRI,
} from '../tools/IRITools';
import {
  isAssetUploaded,
  isAssetPreviewReady,
} from '../tools/AssetTools';

const initialState = {
  assets: [],
  assetsIndexesMap: {},

  creationProgress: {
    toCreate: [],
    creationInProgress: [],
    creationDone: [],
  },
  uploadProgress: {
    toUpload: [],
    uploadInProgress: [],
    uploadDone: [],
  },
  transcodeProgress: {
    transcodeInProgress: [],
  },
};

/**
 * WARNING !!!
 * OUTSIDE STORE FILE ARRAY !!!
 * NEED TO BE RESETTED WHILE CHANGING PROJECT -> STOPS UPLOAD
 */
let filesToUpload = [];
export const getFileToUploadFromIndex = (fileIdx) => {
  if (fileIdx < filesToUpload.length) {
    return filesToUpload[fileIdx];
  }
  return null;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case PROJECTSACTIONS.RESET_CURRENT_PROJECT:
    {
      // reset files to upload
      filesToUpload = [];

      return {
        ...initialState,
      };
    }

    case ACTIONS.NEW_ASSET_REQUEST:
    {
      return {
        ...state,
        status: ACTIONS.NEW_ASSET_REQUEST,
      };
    }

    case ACTIONS.NEW_ASSET_SUCCESS:
    {
      const newlyCreatedAsset = action.payload;

      // Prepend the newly created asset to the current list of assets
      // Increment the number of total items
      const newAssets = [...state.assets];
      newAssets.unshift(newlyCreatedAsset);

      const newAssetsIndexesMap = {};
      newAssets.forEach((elem, index) => {
        newAssetsIndexesMap[getAssetIRI(elem)] = index;
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.NEW_ASSET_SUCCESS,
        assets: newAssets,
        assetsIndexesMap: newAssetsIndexesMap,
      };
    }

    case ACTIONS.NEW_ASSET_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.NEW_ASSET_FAILURE,
      };

    case ACTIONS.GET_ASSETS_REQUEST:
      return {
        ...state,
        error: null,
        status: ACTIONS.GET_ASSETS_REQUEST,
      };

    case ACTIONS.GET_ASSETS_SUCCESS:
    {
      let newAssets = [...state.assets];

      if ((typeof newAssets === 'undefined')
              || (newAssets === null)
              || (newAssets.length === 0)) {
        newAssets = action.assetsData.assets;
      } else {
        action.assetsData.assets.forEach((asset) => {
          const assetIdx = newAssets.findIndex(elem => elem.uuid === asset.uuid);
          if (assetIdx === -1) {
            newAssets.push(asset);
          } else {
            newAssets.splice(assetIdx, 1, asset);
          }
        });
      }

      const newAssetsIndexesMap = {};
      newAssets.forEach((elem, index) => {
        newAssetsIndexesMap[getAssetIRI(elem)] = index;
      });

      // If we are waiting for end of transcoding, parse the retrieved assets
      // to see if they are in the waiting array
      const newTranscodeProgress = [...state.transcodeProgress.transcodeInProgress];
      if (newTranscodeProgress.length > 0) {
        action.assetsData.assets.forEach((asset) => {
          const idx = newTranscodeProgress.findIndex(elem => elem === getAssetIRI(asset));
          if ((idx !== -1)
           && (isAssetUploaded(asset))
           && (isAssetPreviewReady(asset))) {
            newTranscodeProgress.splice(idx, 1);
          }
        });
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.GET_ASSETS_SUCCESS,
        assets: newAssets,
        assetsIndexesMap: newAssetsIndexesMap,
        transcodeProgress: {
          ...state.transcodeProgress,
          transcodeInProgress: newTranscodeProgress,
        },
      };
    }
    case ACTIONS.GET_ASSETS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.GET_ASSETS_FAILURE,
      };

    case ACTIONS.GET_ASSET_REQUEST:
      return {
        ...state,
        error: null,
        status: ACTIONS.GET_ASSET_REQUEST,
      };

    case ACTIONS.GET_ASSET_SUCCESS:
    {
      // Clone and push new asset to state.assets
      const newAssets = [...state.assets];
      const assetIndex = state.assetsIndexesMap[getAssetIRI(action.asset)];
      if ((typeof assetIndex !== 'undefined')
       && (assetIndex !== null)) {
        newAssets.splice(assetIndex, 1, action.asset); // replace at the same index
      } else {
        newAssets.push(action.asset);
      }

      // Create Assets Indexes Map
      const newAssetsIndexesMap = {};
      newAssets.forEach((elem, index) => {
        newAssetsIndexesMap[getAssetIRI(elem)] = index;
      });

      // If we are waiting for end of transcoding, see if the asset is in the waiting array
      const newTranscodeProgress = [...state.transcodeProgress.transcodeInProgress];
      if (newTranscodeProgress.length > 0) {
        const idx = newTranscodeProgress.findIndex(elem => elem === getAssetIRI(action.asset));
        if ((idx !== -1)
          && (isAssetUploaded(action.asset))
          && (isAssetPreviewReady(action.asset))) {
          newTranscodeProgress.splice(idx, 1);
        }
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.GET_ASSET_SUCCESS,
        assets: newAssets,
        assetsIndexesMap: newAssetsIndexesMap,
        transcodeProgress: {
          ...state.transcodeProgress,
          transcodeInProgress: newTranscodeProgress,
        },
      };
    }

    case ACTIONS.GET_ASSET_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.GET_ASSET_FAILURE,
      };

    case ACTIONS.UPDATE_ASSET_REQUEST:
      return {
        ...state,
        error: null,
        status: ACTIONS.UPDATE_ASSET_REQUEST,
      };

    case ACTIONS.UPDATE_ASSET_SUCCESS:
    {
      const modifiedAsset = action.asset;

      const newAssets = [...state.assets];
      const assetIndex = state.assetsIndexesMap[getAssetIRI(modifiedAsset)];
      if ((typeof assetIndex !== 'undefined')
       && (assetIndex !== null)) {
        newAssets.splice(assetIndex, 1, modifiedAsset); // replace at the same index
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.UPDATE_ASSET_SUCCESS,
        assets: newAssets,
      };
    }

    case ACTIONS.UPDATE_ASSET_THUMBNAIL_SUCCESS:
    {
      const modifiedAsset = action.asset;

      const newAssets = [...state.assets];
      const assetIndex = state.assetsIndexesMap[getAssetIRI(modifiedAsset)];
      if ((typeof assetIndex !== 'undefined')
       && (assetIndex !== null)) {
        const newAsset = { ...newAssets[assetIndex] };
        newAsset.assetThumbnail = { ...action.assetThumbnail };
        newAssets.splice(assetIndex, 1, newAsset); // replace at the same index
      }

      return {
        ...state,
        error: null,
        assets: newAssets,
      };
    }

    case ACTIONS.UPDATE_ASSET_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.UPDATE_ASSET_FAILURE,
      };

    case ACTIONS.UPDATE_ASSETS_LABELS_REQUEST:
    case ACTIONS.UPDATE_ASSETS_LABELS_SUCCESS:
    {
      const newAssets = [...state.assets];
      action.changedData.forEach((changedAssetData) => {
        const assetIndex = state.assetsIndexesMap[getAssetIRI(changedAssetData.asset)];
        if ((typeof assetIndex !== 'undefined')
         && (assetIndex !== null)) {
          newAssets.splice(assetIndex, 1, changedAssetData.asset); // replace at the same index
        }
      });

      return {
        ...state,
        error: null,
        status: action.type,
        assets: newAssets,
      };
    }

    case ACTIONS.UPDATE_ASSETS_LABELS_FAILURE:
    {
      const newAssets = [...state.assets];
      action.assets.forEach((assetToRestore) => {
        const assetIndex = state.assetsIndexesMap[getAssetIRI(assetToRestore)];
        if ((typeof assetIndex !== 'undefined')
         && (assetIndex !== null)) {
          newAssets.splice(assetIndex, 1, assetToRestore); // replace at the same index
        }
      });

      return {
        ...state,
        error: action.error,
        status: ACTIONS.UPDATE_ASSETS_LABELS_FAILURE,
        assets: newAssets,
      };
    }

    case ACTIONS.DELETE_ASSETS_REQUEST:
    {
      // If the user wants to delete an asset, it means too that he does not want the uploaded file
      // So if the delete occurs between the asset creation and the file upload, we have to remove
      // the file from the upload queue
      const newToUpload = [...state.uploadProgress.toUpload];
      if ((newToUpload.length > 0)) {
        // Try to find the deleting asset in the upload queue
        for (let i = 0; i < action.assets.length; i += 1) {
          if (isUploadPending(action.assets[i].cloudResourceUploadStatus)) {
            const assetIRI = getAssetIRI(action.assets[i]);
            const idx = newToUpload.findIndex((uploadItem) => {
              return (assetIRI === getAssetIRI(uploadItem.asset));
            });
            if (idx !== -1) {
              newToUpload.splice(idx, 1);
            }
          }
        }
      }

      return {
        ...state,
        status: ACTIONS.DELETE_ASSETS_REQUEST,
        uploadProgress: {
          ...state.uploadProgress,
          toUpload: newToUpload,
        },
      };
    }

    case ACTIONS.DELETE_ASSETS_SUCCESS:
    {
      const newAssets = [...state.assets];
      action.deletedAssets.forEach((deletedAsset) => {
        const assetIndex = newAssets.findIndex((asset) => {
          return (getAssetIRI(asset) === getAssetIRI(deletedAsset));
        });
        newAssets.splice(assetIndex, 1); // remove at the same index
      });

      const newAssetsIndexesMap = {};
      newAssets.forEach((elem, index) => {
        newAssetsIndexesMap[getAssetIRI(elem)] = index;
      });

      // If we are waiting for end of transcoding, we check if the deleted asset
      // is in the waiting array
      const newTranscodeProgress = [...state.transcodeProgress.transcodeInProgress];
      if (newTranscodeProgress.length > 0) {
        action.deletedAssets.forEach((deletedAsset) => {
          const idx = newTranscodeProgress.findIndex(elem => elem === getAssetIRI(deletedAsset));
          if (idx !== -1) {
            newTranscodeProgress.splice(idx, 1);
          }
        });
      }

      return {
        ...state,
        error: null,
        status: ACTIONS.DELETE_ASSETS_SUCCESS,
        assets: newAssets,
        assetsIndexesMap: newAssetsIndexesMap,
        transcodeProgress: {
          ...state.transcodeProgress,
          transcodeInProgress: newTranscodeProgress,
        },
      };
    }

    case ACTIONS.DELETE_ASSETS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_ASSETS_FAILURE,
      };

    case LABELSACTIONS.DELETE_LABEL_SUCCESS:
    {
      if ((typeof action.assets === 'undefined')
       || (action.assets === null)
       || (action.assets.length === 0)) {
        return {
          ...state,
        };
      }

      const newAssets = [...state.assets];

      action.assets.forEach((labelledAssetIRI) => {
        const index = state.assetsIndexesMap[labelledAssetIRI];
        if ((typeof index !== 'undefined')
         && (index !== null)) {
          const assetToModify = { ...newAssets[index] };
          const newAssetLabels = [...assetToModify.labels];
          const idx = newAssetLabels.findIndex(lblIRI => lblIRI === action.labelIRI);
          if (idx !== -1) {
            newAssetLabels.splice(idx, 1);
            assetToModify.labels = newAssetLabels;
          }

          newAssets.splice(index, 1, assetToModify); // replace at the same index
        }
      });

      return {
        ...state,
        assets: newAssets,
      };
    }

    case ACTIONS.ADD_FILES_TO_CREATION_QUEUE:
    {
      const filesToUploadLength = filesToUpload.length;

      // reset files to upload
      filesToUpload = filesToUpload.concat([...action.files]);

      const newFiles = [...state.creationProgress.toCreate];
      for (let i = 0; i < action.files.length; i += 1) {
        const newFileData = {
          idxInUpload: i + filesToUploadLength,
        };
        // labels ?
        if ((typeof action.labels !== 'undefined')
         && (action.labels !== null)
         && (action.labels.length !== 0)) {
          newFileData.labels = [...action.labels];
        }
        // endOfCreationCallback ?
        if ((typeof action.endOfCreationCallback !== 'undefined')
         && (action.endOfCreationCallback !== null)) {
          newFileData.endOfCreationCallback = action.endOfCreationCallback;
        }
        // nodeIRI ?
        if ((typeof action.nodeIRI !== 'undefined')
         && (action.nodeIRI !== null)) {
          newFileData.nodeIRI = action.nodeIRI;
        }
        newFiles.push(newFileData);
      }


      return {
        ...state,
        creationProgress: {
          ...state.creationProgress,
          toCreate: newFiles,
        },
      };
    }

    case ACTIONS.START_ASSET_CREATION:
    {
      const newToCreate = [...state.creationProgress.toCreate];
      const newCreationProgress = [...state.creationProgress.creationInProgress];

      newCreationProgress.push(action.fileIdxInUploadArray);

      // Find the right index, EVEN if it is 0 !
      const idxtodel = newToCreate.findIndex(fd => fd.idxInUpload === action.fileIdxInUploadArray);
      if (idxtodel !== -1) {
        newToCreate.splice(idxtodel, 1);
      }

      return {
        ...state,
        creationProgress: {
          ...state.creationProgress,
          toCreate: newToCreate,
          creationInProgress: newCreationProgress,
        },
      };
    }

    case ACTIONS.END_ASSET_CREATION:
    {
      const newCreationProgress = [...state.creationProgress.creationInProgress];
      const newCreationDone = [...state.creationProgress.creationDone];

      const newToUpload = [...state.uploadProgress.toUpload];

      newCreationDone.push(action.fileIdxInUploadArray);

      // Find the right index, EVEN if it is 0 !
      const idxtodel = newCreationProgress.findIndex(idx => idx === action.fileIdxInUploadArray);
      if (idxtodel !== -1) {
        newCreationProgress.splice(idxtodel, 1);
      }

      newToUpload.push({
        fileIdxInUploadArray: action.fileIdxInUploadArray,
        asset: action.asset,
      });

      return {
        ...state,
        creationProgress: {
          ...state.creationProgress,
          creationInProgress: newCreationProgress,
          creationDone: newCreationDone,
        },
        uploadProgress: {
          ...state.uploadProgress,
          toUpload: newToUpload,
        },
      };
    }

    case ACTIONS.END_ASSET_CREATION_LOOP:
    {
      // Do Nothing yet - Just in case
      // creationProgress is resetted in END_ASSET_UPLOAD_LOOP
      return {
        ...state,
      };
    }

    case ACTIONS.START_ASSET_UPLOAD:
    {
      const newToUpload = [...state.uploadProgress.toUpload];
      const newUploadProgress = [...state.uploadProgress.uploadInProgress];

      newUploadProgress.push(action.assetFile);

      // Find the right index, EVEN if it is 0 !
      let idx = -1;
      if (action.assetFile.asset !== null) {
        const actionassetiri = getAssetIRI(action.assetFile.asset);

        idx = newToUpload.findIndex((uploadItem) => {
          const uploadiri = getAssetIRI(uploadItem.asset);
          return (actionassetiri === uploadiri);
        });
      } else {
        idx = newToUpload.findIndex((uploadItem) => {
          return (uploadItem.fileIdxInUploadArray === action.assetFile.fileIdxInUploadArray);
        });
      }
      if (idx !== -1) {
        newToUpload.splice(idx, 1);
      }

      return {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          toUpload: newToUpload,
          uploadInProgress: newUploadProgress,
        },
      };
    }

    case ACTIONS.END_ASSET_UPLOAD:
    {
      const newUploadProgress = [...state.uploadProgress.uploadInProgress];
      const newUploadDone = [...state.uploadProgress.uploadDone];
      const newTranscodeProgress = [...state.transcodeProgress.transcodeInProgress];

      newUploadDone.push(action.assetFile);

      // Find the right index, EVEN if it is 0 !
      let idx = -1;
      let actionassetiri = null;
      if (action.assetFile.asset !== null) {
        actionassetiri = getAssetIRI(action.assetFile.asset);

        idx = newUploadProgress.findIndex((uploadItem) => {
          const uploadiri = getAssetIRI(uploadItem.asset);
          return (actionassetiri === uploadiri);
        });
      } else {
        idx = newUploadProgress.findIndex((uploadItem) => {
          return (uploadItem.fileIdxInUploadArray === action.assetFile.fileIdxInUploadArray);
        });
      }
      if (idx !== -1) {
        newUploadProgress.splice(idx, 1);
      }

      newTranscodeProgress.push(actionassetiri);

      return {
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          uploadInProgress: newUploadProgress,
          uploadDone: newUploadDone,
        },
        transcodeProgress: {
          ...state.transcodeProgress,
          transcodeInProgress: newTranscodeProgress,
        },
      };
    }

    case ACTIONS.END_ASSET_UPLOAD_LOOP:
    {
      // Reset main array of files
      filesToUpload = [];

      return {
        ...state,
        creationProgress: {
          toCreate: [],
          creationInProgress: [],
          creationDone: [],
        },
        uploadProgress: {
          toUpload: [],
          uploadInProgress: [],
          uploadDone: [],
        },
      };
    }

    default:
      return state;
  }
};
