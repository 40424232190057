/**
 * projectSummary file
 * Contains methods that computes the project summary data
 * XXX This algorithm SHOULD NOT BE THERE !
 * XXX It should be done on back side, because it is also used by PLGen
 * XXX Currently, on front side, we do not take care of already used video segments,
 * XXX so if a label appears twice, this front algorithm considers the same randomness
 * XXX for the both uses of labels, BUT PLGen Does not the same !
 * XXX --> TODO --> USE A SINGLE ALGO TO COMPUTE THE VIDEO PATHS
 */
import {
  getConnectableFromIRI,
  getDkiforkingSoundtracks,
} from './nodesSelectors';
import { getLabelFromIRI } from './labelsSelectors';
import { isKeyInstance } from '../tools/ConnectableTools';
import { getDkIRI, getDkvIRI } from '../tools/IRITools';

/**
 * Compute Project Summary
 * {
 *   nbScenarios   : Number of scenario
 *   nbVersions    : Number of versions of displayable Movies
 *                   may be different from scenario according to the nb of assets per storyblocks
 * }
 */
export const computeCurrentProjectSummary = (state) => {
  const summary = {
    nbPersonna: 0,
    nbVersions: 0,
  };

  if ((typeof state.projects.currentProject === 'undefined')
   || (state.projects.currentProject === null)
   || (typeof state.nodes.rootConnectables === 'undefined')
   || (state.nodes.rootConnectables === null)
   || (typeof state.nodes.rootConnectables.videoRootConnectable === 'undefined')
   || (state.nodes.rootConnectables.videoRootConnectable === null)) {
    return summary;
  }

  console.time('computeCurrentProjectSummary');

  const { currentProject } = state.projects;
  const { links } = currentProject;
  const { dataKeys } = state.dataKeys;
  const {
    videoRootConnectable,
  } = state.nodes.rootConnectables;

  // Are there multiple audio tracks ?
  // If yes how many, and on which variable ?
  let nbAudioTracks = 1;
  const multipleTracksDki = getDkiforkingSoundtracks(state);
  if (multipleTracksDki) {
    const usedLabels = [];
    if ((typeof multipleTracksDki.outputLinks !== 'undefined')
     && (multipleTracksDki.outputLinks !== null)
     && (multipleTracksDki.outputLinks.length !== 0)) {
      multipleTracksDki.outputLinks.forEach((lnk) => {
        const toCon = getConnectableFromIRI(state, lnk.to);
        if ((typeof toCon !== 'undefined')
         && (toCon !== null)) {
          if (usedLabels.findIndex(lblIRI => lblIRI === toCon.label) === -1) {
            usedLabels.push(toCon.label);
          }
        }
      });
    }
    nbAudioTracks = usedLabels.length;
  }

  // Get all combinations of dkvs
  let dkvCombinations = [];
  let dk = null;
  let dkv = null;
  let newCombinations = null;
  if ((typeof dataKeys !== 'undefined')
   && (dataKeys !== null)
   && (dataKeys.length !== 0)
   && (typeof links !== 'undefined')
   && (links !== null)
   && (links.length !== 0)) {
    for (let dki = 0; dki < dataKeys.length; dki += 1) {
      dk = dataKeys[dki];
      if ((typeof dk.dataKeyValues !== 'undefined')
       && (dk.dataKeyValues !== null)) {
        if (dkvCombinations.length === 0) {
          for (let dkvi = 0; dkvi < dk.dataKeyValues.length; dkvi += 1) {
            dkv = dk.dataKeyValues[dkvi];
            dkvCombinations.push([{
              dk: getDkIRI(dk),
              dkv: getDkvIRI(dkv),
            }]);
          } // for dkv
        } else {
          newCombinations = [];
          for (let dkvi = 0; dkvi < dk.dataKeyValues.length; dkvi += 1) {
            dkv = dk.dataKeyValues[dkvi];
            for (let combi = 0; combi < dkvCombinations.length; combi += 1) {
              const newarr = [...dkvCombinations[combi]];
              newarr.push({
                dk: getDkIRI(dk),
                dkv: getDkvIRI(dkv),
              });
              newCombinations.push(newarr);
            } // for combinations
          } // for dkv
          dkvCombinations = [...newCombinations];
        }
      }
    } // for dk
  }// if

  if (dkvCombinations.length === 0) {
    dkvCombinations.push([[]]);// Fake when no DK/DKV defined
  }

  let scenarioNumber = 0;
  let nbVideos = 0;
  const scenariiArray = [];

  let label = null;
  let labelIRI = null;

  const nbCombinations = dkvCombinations.length;
  if (nbCombinations !== 0) {
    const root = getConnectableFromIRI(state, videoRootConnectable);

    let currentScenarLength = 0;
    let scenariiArrayLength = 0;
    let scenar = null;
    for (let combi = 0; combi < nbCombinations; combi += 1) {
      const {
        videoPath: combinationPath,
        isSoundDataKeyUsed,
      } = getCombinationStoryblocksPath(
        state,
        root,
        dkvCombinations[combi],
        [],
        multipleTracksDki,
      );
      if (combinationPath !== null) {
        // Is the new path already in the scenarii array ?
        let foundScenar = false;
        scenariiArrayLength = scenariiArray.length;
        for (let scenari = 0;
          ((scenari < scenariiArrayLength) && (!foundScenar));
          scenari += 1) {
          scenar = scenariiArray[scenari];

          currentScenarLength = scenar.length;
          if (currentScenarLength === combinationPath.length) {
            let stop = false;
            for (let labeli = 0; ((labeli < currentScenarLength) && (!stop)); labeli += 1) {
              if (scenar[labeli] !== combinationPath[labeli]) {
                stop = true;
              }
            }// for
            if (!stop) {
              foundScenar = true;
            }
          }
        }// for

        if (!foundScenar) {
          // scenariiArray.push(combinationPath);
          if (isSoundDataKeyUsed) {
            scenariiArray.push(combinationPath);
          } else {
            for (let iAudioTrack = 0; iAudioTrack < nbAudioTracks; iAudioTrack += 1) {
              scenariiArray.push(combinationPath);
            }
          }
        }
      }
    } // for combination
    scenarioNumber = scenariiArray.length;

    let nbVideosForThisScenar = 0;
    for (let scenarI = 0; scenarI < scenarioNumber; scenarI += 1) {
      nbVideosForThisScenar = 1;

      currentScenarLength = (scenariiArray[scenarI]).length;
      for (let i = 0;
        ((i < currentScenarLength) && (nbVideosForThisScenar !== 0));
        i += 1) {
        labelIRI = (scenariiArray[scenarI])[i];

        if ((typeof labelIRI === 'undefined')
         || (labelIRI === null)) {
          nbVideosForThisScenar = 0;
        } else {
          label = getLabelFromIRI(state, labelIRI);
          if ((typeof label === 'undefined')
           || (label === null)) {
            nbVideosForThisScenar = 0;
          } else if ((typeof label.assets === 'undefined')
                  || (label.assets === null)
                  || (label.assets.length === 0)) {
            nbVideosForThisScenar = 0;
          } else {
            nbVideosForThisScenar *= label.assets.length;
          }
        }
      }

      nbVideos += nbVideosForThisScenar;
    }
  }
  summary.nbPersonna = scenarioNumber;
  summary.nbVersions = nbVideos;

  console.timeEnd('computeCurrentProjectSummary');

  return summary;
};// getCurrentProjectSummary


/**
 * getCombinationStoryblocksPath
 * Retrieves the path of storyblocks for the given dk/dkv combination under a given connectable
 * @param {*} state current state
 * @param {*} connIRI IRI of the connect
 * @param {*} dkvCombination dk/dkv combination
 * @param {*} upperPath storyblock path above the given connectable
 */
export const getCombinationStoryblocksPath = (
  state,
  connectable,
  dkvCombination,
  upperPath,
  soundDki,
) => {
  if (connectable === undefined) {
    return null;
  }

  let videoPath = [...upperPath];

  let isSoundDataKeyUsed = false;
  let outputLnk = null;
  if (isKeyInstance(connectable)) {
    if ((typeof connectable.outputLinks !== 'undefined')
     && (connectable.outputLinks !== null)
     && (connectable.outputLinks.length !== 0)) {
      // Find the parameter used in the combination for this KeyInstance
      let foundDkParam = null;
      const dkvCombinationLength = dkvCombination.length;
      for (let dki = 0; ((dki < dkvCombinationLength) && (foundDkParam === null)); dki += 1) {
        if (connectable.dataKey === dkvCombination[dki].dk) {
          foundDkParam = dkvCombination[dki];
          if ((typeof soundDki !== 'undefined')
           && (soundDki !== null)
           && (soundDki.dataKey === connectable.dataKey)) {
            isSoundDataKeyUsed = true;
          }
        }
      }

      if (foundDkParam === null) {
        videoPath = null;
      } else {
        let found = false;
        const nbOutputLinks = connectable.outputLinks.length;
        for (let lnki = 0; ((lnki < nbOutputLinks) && !found) ; lnki += 1) {
          const lnk = connectable.outputLinks[lnki];
          if (lnk.dataKeyValue === foundDkParam.dkv) {
            found = true;
            outputLnk = lnk;
          }
        }// for
        if (!found) {
          videoPath = null;
        }
      }
    }
  } else {
    videoPath.push(connectable.label);
    if ((typeof connectable.outputLinks !== 'undefined')
     && (connectable.outputLinks !== null)
     && (connectable.outputLinks.length !== 0)) {
      const [first] = connectable.outputLinks;
      outputLnk = first;
    }
  }
  if (outputLnk !== null) {
    const tocon = getConnectableFromIRI(state, outputLnk.to);

    const {
      videoPath: newVideoPath,
      isSoundDataKeyUsed: newIsSoundDataKeyUsed,
    } = getCombinationStoryblocksPath(
      state,
      tocon,
      dkvCombination,
      videoPath,
      soundDki,
    );
    videoPath = newVideoPath;
    if (newIsSoundDataKeyUsed) {
      isSoundDataKeyUsed = true;
    }
  }

  return {
    videoPath,
    isSoundDataKeyUsed,
  };
};// getCombinationStoryblocksPath
