import {
  clone,
} from 'lodash';
import CONSTANTS from '../Constants';

const INITIAL_UNDO_HISTORY_STATE = {
  undoQueue: [],
  redoQueue: [],
  undoying: null,
  redoying: null,
  canUndo: false,
  canRedo: false,
};

export default function undoHistoryReducer(state = INITIAL_UNDO_HISTORY_STATE, action) {
  const { type, payload: undoRedoItem } = action;
  switch (type) {
    case 'UNDO_HISTORY@STARTUNDO':
    {
      const { undoQueue } = state;

      return (undoQueue.length === 0) ? state : {
        ...state,
        undoying: undoQueue[0],
        redoying: null,
        canUndo: false,
        canRedo: false,
      };
    }
    case 'UNDO_HISTORY@UNDODONE':
    {
      const { undoQueue, redoQueue } = state;

      let newState = { ...state };
      if (action.success) {
        if (undoQueue.length !== 0) {
          let newUndoQueue = undoQueue.slice(1);
          const undoneItem = clone(undoQueue[0]);

          const newRedoQueue = [undoneItem, ...redoQueue];

          // In may be same cases (creation commands) we need to patch the args
          // of the redo-able commands
          let patchedRedoQueue = newRedoQueue.map(element => (clone(element)));
          if ((action.paramsToPatch) && (patchedRedoQueue.length > 0)) {
            patchedRedoQueue = patchParamsIfNecessary(patchedRedoQueue, action.paramsToPatch);
          }

          if ((action.paramsToPatch) && (newUndoQueue.length > 0)) {
            newUndoQueue = patchParamsIfNecessary(newUndoQueue, action.paramsToPatch);
          }

          newState = {
            undoQueue: newUndoQueue,
            redoQueue: patchedRedoQueue,
            undoying: null,
            redoying: null,
            canUndo: newUndoQueue.length > 0,
            canRedo: true,
          };
        }
      } else {
        // If the undone failed, we keep the queues like they were before
        newState = {
          ...state,
          undoying: null,
          redoying: null,
          canUndo: undoQueue.length > 0,
          canRedo: redoQueue.length > 0,
        };
      }
      return newState;
    }
    case 'UNDO_HISTORY@STARTREDO':
    {
      const { redoQueue } = state;

      return (redoQueue.length === 0) ? state : {
        ...state,
        undoying: null,
        redoying: redoQueue[0],
        canUndo: false,
        canRedo: false,
      };
    }
    case 'UNDO_HISTORY@REDODONE':
    {
      const newState = clone(state);

      const { undoQueue, redoQueue } = newState;

      if (action.success) {
        if (redoQueue.length !== 0) {
          // In may be same cases (creation commands) we need to patch the args
          // of the redo-able commands
          let patchedRedoQueue = redoQueue.map(element => (clone(element)));
          if ((action.paramsToPatch) && (patchedRedoQueue.length > 0)) {
            patchedRedoQueue = patchParamsIfNecessary(patchedRedoQueue, action.paramsToPatch);
          }

          let newUndoQueue = undoQueue.map(element => (clone(element)));
          if ((action.paramsToPatch) && (newUndoQueue.length > 0)) {
            newUndoQueue = patchParamsIfNecessary(newUndoQueue, action.paramsToPatch);
          }

          const newRedoQueue = patchedRedoQueue.slice(1);
          const redoneItem = clone(patchedRedoQueue[0]);
          // In some cases (creation commands) we need to recompute
          // the undo args when the redo succeeded
          if (action.undoargs) {
            redoneItem.undoargs = clone(action.undoargs);
          }

          return {
            undoQueue: [redoneItem, ...newUndoQueue],
            redoQueue: newRedoQueue,
            undoying: null,
            redoying: null,
            canUndo: true,
            canRedo: newRedoQueue.length > 0,
          };
        }
      }
      // If the redone failed, we keep the queues like they were before
      return {
        ...state,
        undoying: null,
        redoying: null,
        canUndo: undoQueue.length > 0,
        canRedo: redoQueue.length > 0,
      };
    }
    case 'UNDO_HISTORY@ADD':
    {
      const { undoQueue } = state;

      let newUndoQueue = [];
      if ((typeof undoRedoItem !== 'undefined') && (undoRedoItem !== null)) {
        newUndoQueue = [undoRedoItem, ...undoQueue];
      } else {
        newUndoQueue = [...undoQueue];
      }
      return {
        undoQueue: newUndoQueue,
        redoQueue: [],
        undoying: null,
        redoying: null,
        canUndo: true,
        canRedo: false,
      };
    }
    case 'UNDO_HISTORY@CLEAR':
    {
      return INITIAL_UNDO_HISTORY_STATE;
    }
    default:
      return state;
  }
}

function patchParamsIfNecessary(commandQueue, paramsToPatch) {
  const modifiedCommandQueue = clone(commandQueue);

  for (let i = 0; i < modifiedCommandQueue.length; i += 1) {
    const command = clone(modifiedCommandQueue[i]);

    // Patch Action
    command.action = patchArgsIfNecessary(clone(command.action), paramsToPatch, true);

    // Patch Undo Args
    command.undoargs = patchArgsIfNecessary(clone(command.undoargs), paramsToPatch, true);

    // Patch Redo Args
    command.redoargs = patchArgsIfNecessary(clone(command.redoargs), paramsToPatch, true);

    modifiedCommandQueue[i] = command;
  }

  return modifiedCommandQueue;
}

function patchArgsIfNecessary(args, paramsToPatch, recursive) {
  const newargs = {};
  const keys = Object.keys(args);
  for (let j = 0; j < keys.length; j += 1) {
    const key = keys[j];

    newargs[key] = patchArgIfNecessary(args[key], paramsToPatch, recursive);
  }
  return newargs;
}

/**
 * Replaces, if necessary, the old param by the new one in the given argument
 * @param {*} arg command argument to patch if it contains the given parameter
 * @param {*} paramsToPatch Parameter with old value and new value
 */
function patchArgIfNecessary(arg, paramsToPatch, recursive) {
  const newarg = clone(arg);

  if (typeof arg === 'string') {
    // String - nothing to patch
    return newarg;
  }

  for (let i = 0; i < paramsToPatch.length; i += 1) {
    const param = paramsToPatch[i];

    if ((typeof newarg[CONSTANTS.TYPE_FIELD] !== 'undefined')
     && (newarg[CONSTANTS.TYPE_FIELD] !== null)
     && (newarg[CONSTANTS.TYPE_FIELD] === param.type)
     && (typeof newarg[param.field] !== 'undefined')
     && (newarg[param.field] !== null)
     && (newarg[param.field] === param.previousvalue)) {
      newarg[param.field] = param.newvalue;
    }
  }// for

  if (recursive) {
    const keys = Object.keys(newarg);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      if (key !== CONSTANTS.TYPE_FIELD) {
        if (newarg[key] !== null) {
          if (Array.isArray(newarg[key])) {
            const argarray = clone(newarg[key]);

            for (let j = 0; j < argarray.length; j += 1) {
              argarray[j] = patchArgIfNecessary(argarray[j], paramsToPatch);
            }
            newarg[key] = argarray;
          } else if (typeof newarg[key] === 'object') {
            newarg[key] = patchArgIfNecessary(newarg[key], paramsToPatch);
          }
        }
      }
    }// for
  }

  return newarg;
}
