import React from 'react';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  Header: {
    backgroundColor: '#FFFFFF',
    flex: 'none',
    width: '100%',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    position: 'static',
  },
  Header_homelink: {
    flexGrow: 0,
    width: '27px',
    height: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: 'transparent',
    border: 0,
  },
  Header_homelinkEnabled: {
    cursor: 'pointer',
  },
  Header_actionBar: {
    flexGrow: 1,
    flexBasis: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
  },
  Toolbar: {
    height: '48px',
    margin: '0px 4px 0px 4px',
  },
};


const SkwrHeader = ({
  className,
  classes,
  onLogo,
  children,
  logo
}) => {
  const handleClick = () => {
    if (onLogo) {
      onLogo();
    }
  };

  let homeLinkClass = classNames(classes.Header_homelink);
  if (onLogo) {
    homeLinkClass = classNames(classes.Header_homelink, classes.Header_homelinkEnabled);
  }

  return (
    <AppBar className={classNames(classes.Header, className)}>
      <Toolbar className={classes.Toolbar} disableGutters>
        <div
          role="button"
          className={homeLinkClass}
          onClick={handleClick}
        >
          {logo && logo}
        </div>
        <div className={classes.Header_actionBar}>
          {children}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(SkwrHeader);
