/* eslint-disable no-param-reassign */
import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getNewDkvColor } from '../tools/DataKeyTools';
import { getDkIRI, getDkvIRI } from '../tools/IRITools';

// ************************************************************ dataKey Helper

export const parseApiDataKey = (dataKeys) => {
  // If dataKeys is an Array
  if (dataKeys instanceof Array) {
    return dataKeys.map((dataKey) => {
      delete dataKey['@context'];
      // KEEP THE TYPE - USEFULL FOR KNOWING THE OBJECT - delete dataKey['@type'];
      // KEEP THE IRI - VERY VERY USESULL -  delete dataKey['@id'];
      return dataKey;
    });
  }

  // Else dataKeys is just a dataKey item
  delete dataKeys['@context'];
  // KEEP THE TYPE - USEFULL FOR KNOWING THE OBJECT - delete dataKey['@type'];
  // KEEP THE IRI - VERY VERY USESULL - delete dataKeys['@id'];
  return dataKeys;
};


/**
 * retrieves the default color of the new DataKeyValue
 * @param {DataKey} dk DataKey under which we create the new DataKeyValue
 */
export const getDefaultDKVColor = (dk) => {
  let DKVs = [{}];
  if ((dk.dataKeyValues !== null) && (typeof dk.dataKeyValues !== 'undefined')) {
    DKVs = dk.dataKeyValues;
  }

  // If there is no DKV -> same color as the dk one
  // If there is one -> Brighter than the last one
  let newColor = dk.color;
  if (DKVs.length !== 0) {
    // get last color
    newColor = DKVs[DKVs.length - 1].color;
  }
  return getNewDkvColor(newColor);
};


// ************************************************************ API REQUEST

/**
 * Retrieves the array of dataKeys of a given project
 * @param {string} projectUuid uuid of the project
 */
export const getDataKeys = (projectUuid) => {
  return new Promise((resolve, reject) => {
    const params = {
      'project.uuid': projectUuid,
    };

    axios.get(`${CONSTANTS.API.PROJECTS_URI}/data_keys`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        const dataKeysData = {
          dataKeys: response.data['hydra:member'],
        };

        resolve(dataKeysData);
      }).catch((error) => {
        rejectUnfilteredError('getDataKeys', error, reject);
      });
  });
};

export const modifyDataKey = (dataKey) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}${getDkIRI(dataKey)}`,
      dataKey,
      {
        headers: authHeader(),
      }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
            if (error.response.data.violations[0].propertyPath === 'technicalName') {
              reject(new Error('modifyDataKey.error.unauthorizedContent'));
              return;
            }
          }
        }
        if (error.response.status === 403) {
          if (error.response.data['@type'] === 'DuplicateDataKeyTechnicalNamePerProjectError') {
            reject(new Error('modifyDataKey.error.dataKeyAlreadyExists'));
            return;
          }
        }
      }
      rejectUnfilteredError('modifyDataKey', error, reject);
    });
  });
};

/**
 * create a new dataKey
 * @param {object} dataKeyData object needed to create the dataKey
 */
export const createDataKey = (dataKeyData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/data_keys`, dataKeyData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }

        if (error.response.status === 403) {
          if (error.response.data['@type'] === 'DuplicateDataKeyTechnicalNamePerProjectError') {
            reject(new Error('newDataKey.error.dataKeyAlreadyExists'));
            return;
          }
        }
      }

      rejectUnfilteredError('newDataKey', error, reject);
    });
  });
};

/**
 * create a new dataKeyValue
 * @param {object} dataKeyValueData object needed to create the dataKeyValue
 */
export const createDataKeyValue = (dataKeyValueData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/data_key_values`, dataKeyValueData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }

        if (error.response.status === 403) {
          if (error.response.data['@type'] === 'DuplicateDataKeyValueTechnicalValuePerDataKeyError') {
            reject(new Error('newDataKeyValue.error.dataKeyValueAlreadyExists'));
            return;
          }
        }
      }

      rejectUnfilteredError('newDataKeyValue', error, reject);
    });
  });
};


/**
 * Action called to modify a dataKeyValue
 * @param {object} dataKeyValue the modified dataKeyValue
 */
export const modifyDataKeyValue = (dataKeyValue) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}${getDkvIRI(dataKeyValue)}`,
      dataKeyValue,
      {
        headers: authHeader(),
      }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
            if (error.response.data.violations[0].propertyPath === 'technicalValue') {
              reject(new Error('modifyDataKeyValue.error.unauthorizedContent'));
              return;
            }
          }
        }
        if (error.response.status === 403) {
          if (error.response.data['@type'] === 'DuplicateDataKeyValueTechnicalValuePerDataKeyError') {
            reject(new Error('modifyDataKeyValue.error.dataKeyValueAlreadyExists'));
            return;
          }
        }
      }
      rejectUnfilteredError('modifyDataKeyValue', error, reject);
    });
  });
};

/**
 * Action called to delete a dataKeyValue
 * @param {string} dataKeyValueID ID of the dataKeyValue to delete
 */
export const deleteDataKeyValue = (dataKeyValue) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${getDkvIRI(dataKeyValue)}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          // Delete action -> don't worry if the object to delete was not found !
          console.error('Unable to delete DataKeyValue : ', error);
          resolve();
          return;
        }
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].propertyPath === 'name') {
              if (error.response.data.violations[0].message !== '') {
                reject(new Error(error.response.data.violations[0].message));
                return;
              }
            }
          }
        } else if (error.response.status === 403) {
          if (error.response.data['@type'] === 'ForbiddenDataKeyValueSuppressionError') {
            if (typeof error.response.data['hydra:description'] !== 'undefined') {
              console.error(error.response.data['hydra:description']);
            }
            reject(Error('deleteDataKeyValue.error.ForbiddenDataKeyValueSuppressionError'));
            return;
          }
        }
      }
      rejectUnfilteredError('deleteDataKeyValue', error, reject);
    });
  });
};

export const deleteDataKey = (dataKeyData) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${getDkIRI(dataKeyData)}`, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          // Delete action -> don't worry if the object to delete was not found !
          console.error('Unable to delete DataKey : ', error);
          resolve();
          return;
        }
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].propertyPath === 'name') {
              if (error.response.data.violations[0].message !== '') {
                reject(new Error(error.response.data.violations[0].message));
                return;
              }
            }
          }
        } else if (error.response.status === 403) {
          if (error.response.data['@type'] === 'ForbiddenDataKeySuppressionRelatedDataKeyValuesError') {
            if (typeof error.response.data['hydra:description'] !== 'undefined') {
              console.error(error.response.data['hydra:description']);
            }
            reject(Error('deleteDataKey.error.ForbiddenDataKeySuppressionRelatedDataKeyValuesError'));
            return;
          }
        }
      }
      rejectUnfilteredError('deleteDataKey', error, reject);
    });
  });
};
