import { nameContainsString } from 'skewerui';
import {
  getObjectIRI,
  getVideoMediaTypeIRI,
  getAudioMediaTypeIRI,
} from './IRITools';

export const isAudioLabel = (label, project) => {
  let audioMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    audioMediaTypeIRI = getAudioMediaTypeIRI(project);
  }

  if ((typeof label.mediaType !== 'undefined')
   && (label.mediaType !== null)) {
    let labelMediaTypeIRI = label.mediaType;
    if (typeof label.mediaType === 'object') {
      labelMediaTypeIRI = getObjectIRI(label.mediaType);
    }
    return (labelMediaTypeIRI === audioMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return false;
};

export const isVideoLabel = (label, project) => {
  let videoMediaTypeIRI = null;
  if ((typeof project !== 'undefined')
   && (project !== null)) {
    videoMediaTypeIRI = getVideoMediaTypeIRI(project);
  }

  if ((typeof label.mediaType !== 'undefined')
   && (label.mediaType !== null)) {
    let labelMediaTypeIRI = label.mediaType;
    if (typeof label.mediaType === 'object') {
      labelMediaTypeIRI = getObjectIRI(label.mediaType);
    }
    return (labelMediaTypeIRI === videoMediaTypeIRI);
  }

  // By default - act as if the asset was a video one
  return true;
};

export const getLabelAssetRefIRI = (label) => {
  let assetIRI = null;

  if ((typeof label.assets !== 'undefined')
   && (label.assets !== null)
   && (label.assets.length > 0)) {
    [assetIRI] = label.assets;
  }
  return assetIRI;
};

/**
 * returns an array of Labels which names correspond to the given string
 * (Given an array of labels)
 * @param {*} suggestion array
 */
export const getLabelSuggestionsArray = (labels, value) => {
  const labelsArray = labels.filter(elem => nameContainsString(elem.name, value));
  return labelsArray.sort((a, b) => {
    return (a.name.localeCompare(b.name));
  });
}; // getLabelSuggestionsArray
