
/**
 * Get current user data
 * @param {*} state The State
 */
export const getCurrentUser = state => state.authentication.user;

/**
 * Get current user data
 * @param {*} state The State
 */
export const getCurrentUserEmail = (state) => {
  if ((typeof state.authentication.user !== 'undefined')
   && (state.authentication.user !== null)
   && (typeof state.authentication.user.email !== 'undefined')
   && (state.authentication.user.email !== null)) {
    return state.authentication.user.email;
  }
  return '';
};

/**
 * Is the user loggedIn
 * @param {*} state The State
 */
export const isLoggedIn = state => state.authentication.loggedIn;

/**
 * Get authentication status
 * @param {*} state The State
 */
export const getAuthenticationStatus = state => state.authentication.status;

/**
 * Get authentication error
 * @param {*} state The State
 */
export const getAuthenticationError = state => state.authentication.error;
