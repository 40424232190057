import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ColorByUsage, Shadows } from './UIConstants';

const useStyles = makeStyles(theme => ({
  toolBar: {
    backgroundColor: ColorByUsage.toolbarBackground,
    borderStyle: 'solid',
    borderRadius: '30px',
    borderColor: ColorByUsage.toolbarForeground,
    boxShadow: Shadows.shadowBlurLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const SkwrToolBar = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.toolBar)}>
      {children}
    </div>
  );
};

export default SkwrToolBar;
