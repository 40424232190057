import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { Shadows, ColorByUsage } from './UIConstants';
import { autosuggestHighlightMatch } from './StringTools';

export const autoSuggestTheme = {
  container: {
    position: 'relative',
    border: 'none',
  },
  containerOpen: {
    border: 'none',
  },
  input: {
    width: '100%',
    padding: '0px 0px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
  },
  inputFocused: {
    border: 'none',
    outline: 'none',
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 20,
    backgroundColor: '#fff',
    fontWeight: 300,
    fontSize: 16,
    overflowY: 'auto',
    maxHeight: '200px',
    zIndex: 2,
    borderRadius: 5,
    boxShadow: Shadows.menuShadow,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 10px',
  },
  suggestionHighlighted: {
    backgroundColor: ColorByUsage.selectItemBackgroundSelected,
  },
};


const styles = theme => ({
  suggestionMatch: {
    fontWeight: 'bold',
  },
});

const SkwrAutosuggest = ({
  classes,
  getSuggestionValue,
  suggestions,
  autoFocus,
  inputProps,
  placeholder,
  ...props
}) => {
  const shouldRenderSuggestions = () => {
    return true;
  }

  const renderSuggestion = (suggestedLabel, { query }) => {
    const value = getSuggestionValue(suggestedLabel);
    const matches = autosuggestHighlightMatch(value, query);
    const parts = AutosuggestHighlightParse(value, matches);

    return (
      <span>
        {parts.map((part, index) => {
          const className = part.highlight ? classes.suggestionMatch : null;
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      shouldRenderSuggestions={shouldRenderSuggestions}
      renderSuggestion={renderSuggestion}
      getSuggestionValue={getSuggestionValue}
      theme={{ ...autoSuggestTheme }}
      inputProps={{
        autoFocus,
        placeholder,
        ...inputProps,
      }}
      {...props}
    />
  );
};

export default withStyles(styles)(SkwrAutosuggest);
