//
// File : UIConstants.jsx
// File containing graphical Constants or common variables used in the UI components
//
import { UAParser } from 'ua-parser-js';

// User Agent constants (parsed by UA-Parser)
const uaparser = new UAParser();
export const CURRENT_OS = uaparser.getOS();
export const CURRENT_BROWSER = uaparser.getBrowser();
export const CURRENT_DEVICE = uaparser.getDevice();

export const IS_MOBILE = ((typeof CURRENT_DEVICE !== 'undefined')
    && (typeof CURRENT_DEVICE.type !== 'undefined')
    && (CURRENT_DEVICE.type === 'mobile'));

export const IS_MACOS = CURRENT_OS.name === 'Mac OS';

export const IS_SAFARI = CURRENT_BROWSER.name === 'Safari';



/*
* Font Sizes
*/
export const FontSizes = {
    formTitle: '45px',
    titleThree: '22px',
    textLarge: '16px',
    textMedium: '14px',
    textSmall: '12px',
    textMicro: '10px',
  };
  
  /*
  * COLORS
  */
  export const Color = {
    red: '#FF3366',
    purple: '#cd66ff',
    purple_RGB: '205, 102, 255',
    purpleLight: '#f2e5f2',
    greyLight: '#F6F6F6',
    greyLight2: '#efefe7',
    greyLight3: '#d1d1d1',
    grey: '#9C9C9C',
    greyAlt: '#949494',
    greyDark: '#2d2d2d',
    black: '#000000',
    white: '#ffffff',
    orange: '#ff6634',
    deepPink: '#FF1493',
    pink: '#f0d2ff',
    yellow: '#ffff00',
    darkPink: '#B32F51',
    green: '#85DC7E',
  };
  
  /*
  * COLORS BY USAGE
  */
  
  export const ColorsType = {
    primary: Color.purple,
    alternative: Color.orange,
  };
  
  export const ColorByUsage = {
    focusColor: ColorsType.primary,
  
    buttonPrimaryBackground: ColorsType.primary,
    buttonPrimaryForeground: Color.white,
    buttonPrimaryDisabled: Color.grey,
  
    buttonSecondaryBackground: Color.white,
    buttonSecondaryForeground: ColorsType.primary,
    buttonSecondaryDisabled: Color.grey,
  
    buttonMajorBackground: Color.red,
    buttonMajorForeground: Color.white,
  
    checkboxPrimaryForeground: ColorsType.primary,
  
    formBackground: Color.greyLight,
    formTitle: Color.greyDark,
    formSubTitle: Color.grey,
  
    linkForeground: ColorsType.primary,
    linkSecondary: Color.grey,
  
    inputBorder: Color.greyAlt,
    inputPlaceholder: Color.grey,
  
    errorColor: '#ff5555',
    errorForeground: Color.white,
  
    cardBackground: Color.white,
    cardBackgroundSelected: Color.pink,
    cardThumbnailBackground: Color.greyLight2,
    cardForeground: Color.greyDark,
    cardSubTitle: Color.greyAlt,
    cardIcon: Color.greyAlt,
  
    tooltipGuideBackground: Color.greyAlt,
    tooltipGuideForeground: Color.white,
    tooltipEllipsisBackground: Color.white,
    tooltipEllipsisForeground: Color.black,
  
    labelBackground: Color.greyLight,
    labelForeground: Color.greyDark,
  
    dataKeyValueDefaultColor: Color.deepPink,
    dataKeyValueBackground: Color.greyLight,
    dataKeyValueForeground: Color.greyDark,
  
    dataKeyBackground: Color.white,
    dataKeyForeground: Color.greyDark,
  
    editableLabelButton: Color.grey,
    editableLabelButtonHover: Color.black,
  
    toolIconsBackground: Color.white,
    toolIconsForeground: Color.greyAlt,
  
    toolbarBackground: Color.white,
    toolbarForeground: Color.greyDark,
  
    contextMenuBackground: Color.white,
    contextMenuForeground: Color.greyDark,
    contextMenuBackgroundSelected: Color.grey,
    contextMenuForegroundSelected: Color.greyDark,
  
    selectBackground: Color.white,
    selectForeground: Color.greyDark,
    selectItemBackgroundSelected: Color.grey,
    selectItemForegroundSelected: Color.greyDark,
  
    workspaceBackground: '#f1efef',
  
    progressCreateAsset: Color.darkPink,
    progressUploadAsset: Color.purple,
  
    tabIndicator: ColorsType.primary,
    tabSelected: Color.greyDark,
    tabFocused: Color.grey,
  };
  
  /*
  * FONTS
  */
  export const Font = {
    mainFont: '\'Fira Sans\', sans-serif',
    altFont: '\'Roboto\', sans-serif',
  };
  
  /*
  * SHADOWS
  */
  
  export const Shadows = {
    shadowBlurLight: '0px 1px 7px 0px #00000038',
    // Following shadow code is copy pasted from the Paper one - beurk
    menuShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  };
  