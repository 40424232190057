//
// File containing Constants or common variables used along the editor
//
import { defineMessages } from 'react-intl';

// Common messages
export const commonMessages = defineMessages({
  // Common Errors
  internalError: {
    id: 'error.internalError',
    defaultMessage: 'Internal error : {internalErrorCode}.',
  },
  noCurrentProject: {
    id: 'error.noCurrentProject',
    defaultMessage: 'Unknown project - You may be disconnected, try to log in again',
  },
  notLoggedIn: {
    id: 'error.notLoggedIn',
    defaultMessage: 'You may be disconnected, try to log in again',
  },
  AwsInvalidAccessKeyIdError: {
    id: 'error.AwsInvalidAccessKeyIdError',
    defaultMessage: 'Please wait some seconds and try to perform operation again',
  },
  projectNotFound: {
    id: 'error.projectNotFound',
    defaultMessage: 'Project not found',
  },

  // Common Buttons
  applyButton: {
    id: 'buttons.applyButton',
    defaultMessage: 'Apply',
  },
  cancelButton: {
    id: 'buttons.cancelButton',
    defaultMessage: 'Cancel',
  },
  closeButton: {
    id: 'buttons.closeButton',
    defaultMessage: 'Close',
  },
  saveButton: {
    id: 'buttons.saveButton',
    defaultMessage: 'Save',
  },
  yesButton: {
    id: 'buttons.yesButton',
    defaultMessage: 'Yes',
  },
  noButton: {
    id: 'buttons.noButton',
    defaultMessage: 'No',
  },
  okButton: {
    id: 'buttons.okButton',
    defaultMessage: 'OK',
  },
  warningTitle: {
    id: 'buttons.warningTitle',
    defaultMessage: 'Warning',
  },

  // Common Placeholders
  emailPlaceholder: {
    id: 'placeholders.emailPlaceholder',
    defaultMessage: 'user@mail.com',
  },

  // Common questions
  doYouWantToContinueQuestion: {
    id: 'questions.doYouWantToContinueQuestion',
    defaultMessage: 'Do you want to continue ?',
  },


  // QuestionDialog existing files
  alreadyExistingAssetsTitle: {
    id: 'MediaLibrary.alreadyExistingAssetsTitle',
    defaultMessage: 'Import existing media ?',
  },
  alreadyExistingAssetsMessage: {
    id: 'MediaLibrary.alreadyExistingAssetsMessage',
    defaultMessage: 'The following files already exists in the project.',
  },
  alreadyExistingAssetsQuestion: {
    id: 'MediaLibrary.alreadyExistingAssetsQuestion',
    defaultMessage: 'Do you want to upload them anyway ? ',
  },
  uploadAllAnswer: {
    id: 'MediaLibrary.uploadAllAnswer',
    defaultMessage: 'Import all files',
  },
  uploadOnlyNewFilesAnswer: {
    id: 'MediaLibrary.uploadOnlyNewFilesAnswer',
    defaultMessage: 'Import new files',
  },
  cancelUploadAnswer: {
    id: 'MediaLibrary.cancelUploadAnswer',
    defaultMessage: 'Cancel upload',
  },


});

//
// Constants used along the editor
//

/**
 * Name of the "editor"
 */
export const PRODUCT_NAME = 'SkewerLab';
/**
 * Name of the "company"
 */
export const COMPANY_NAME = 'SkewerLab';

export default {
  PRODUCT_NAME: 'SkewerLab',
  COMPANY_NAME: 'SkewerLab',
  SUPPORT_EMAIL: 'support@skewerlab.com',
  DEFAULT_LOCALE: 'en',
  client: 'clients/studio',
  environment: `environments/${getClientEnvironment()}`,
  API: {
    // USERS_URI: 'https://dev.auth.api.skwr.io',
    USERS_URI: process.env.REACT_APP_API_AUTH_URL,
    // PROJECTS_URI: 'https://dev.project.api.skwr.io',
    PROJECTS_URI: process.env.REACT_APP_API_PROJECT_URL,
    // API Gateway routes
    EXPORT_SINGLE_URI: process.env.REACT_APP_API_EXPORT_SINGLE_URL,
  },
  IRI: {
    PROJECTS: 'projects/',
    PROJECT: 'project/',
    DATAKEYS: 'data_keys/',
    DATAKEYVALUES: 'data_key_values/',
    KEYINSTANCES: 'key_instances/',
    NODES: 'nodes/',
    LABELS: 'labels/',
    ASSETS: 'assets/',
  },
  USER: {
    LOCAL_STORAGE_KEY: 'user',
    LOCAL_STORAGE_PREFERENCES: 'preferences',
  },
  COMMONERRORS: {
    NO_CURRENT_PROJECT: 'noCurrentProject',
    PROJECT_NOT_FOUND: 'projectNotFound',
    NOT_LOGGED_IN: 'notLoggedIn',
    AWS_INVALID_ACCESS_KEY: 'AwsInvalidAccessKeyIdError',
  },
  TYPE_FIELD: '@type',
  IRI_FIELD: '@id',
  TYPES: {
    DATAKEY: 'DataKey',
    DATAKEYVALUE: 'DataKeyValue',
    KEYINSTANCE: 'KeyInstance',
    NODE: 'Node',
    LINK: 'Link',
  },
  SDK_LATEST_URL: process.env.REACT_APP_SDK_URL,
  PROJECT_VALIDITY: {
    UNKNOWN: 0,
    VALID: 1,
    INVALID: 2,
  },
  INVALID_COORDINATE: 'INVALID_COORDINATE',
};

export function isDemoMode() {
  return JSON.parse(process.env.REACT_APP_IS_DEMO_MODE);
}
export function isDevelopmentMode() {
  return JSON.parse(process.env.REACT_APP_IS_DEVELOPMENT_MODE);
}
export function isProductionMode() {
  return JSON.parse(process.env.REACT_APP_IS_PRODUCTION_MODE);
}

// Return app environment worded to auth API
function getClientEnvironment() {
  if (isDemoMode()) {
    return 'demo';
  }
  if (isDevelopmentMode()) {
    return 'dev';
  }
  if (isProductionMode()) {
    return 'prod';
  }
  return '';
}

/**
 * List of accepted file types for project assets
 * CAUTION : CHECK THEM ON ALL BROWSERS AS ALL BROWSERS DON'T BEHAVE THE SAME WAY
 */
export const ASSETS_FILETYPES = {
  video: [
    'video/mp4',
    'video/quicktime',
    'video/x-matroska',
  ],
  sound: [
    // 'audio/aac', // ! AWS TRANSCODING ERROR ON .aac FILES
    'audio/mpeg', // THIS CORRECT MIMETYPE IS FOR THE BROWSERS THAT STILL DON'T USE CHROMIUM
    'audio/mp3', // AS CHROMIUM IS STUPID, WE HAVE TO USE THIS UNEXISTING MIMETYPE
    // https://bugs.chromium.org/p/chromium/issues/detail?id=227004
  ],
  image: [],
};

/**
 * Image mimetypes
 */
const MIMETYPE_GIF = 'image/gif';
const MIMETYPE_WEBP = 'image/webp';
const MIMETYPE_JPEG = 'image/jpeg';
const MIMETYPE_PNG = 'image/png';
export const THUMBNAIL_FILETYPES = [
  {
    type: MIMETYPE_GIF,
    isResizeable: false,
  },
  {
    type: MIMETYPE_WEBP,
    isResizeable: false,
  },
  {
    type: MIMETYPE_JPEG,
    isResizeable: true,
  },
  {
    type: MIMETYPE_PNG,
    isResizeable: true,
  },
];

/**
 * Defines of all tabs used in the editor
 */
export const TABS = {
  TAB_MEDIA_LIBRARY: 'tabMediaLib',
  TAB_STORY_EDITOR: 'tabStoryEditor',
  TAB_EXPORT_MANAGER: 'tabExportManager',
};

// ASSETS CONSTANTS
export const ASSET = {
  UUID_PATTERN: '{asset.uuid}',
  THUMBFILE_PATTERN: '{assetThumbnail.fileName}',
  THUMBNAIL_FILENAME: '0.jpg',
  PREVIEWFILE_PATTERN: '{asset.preview.m3u8Manifest.fileName}',
};

// Upload status
export const UPLOAD_STATUS = {
  START: '/cloud_resource_upload_statuses/upload_started',
  SUCCESS: '/cloud_resource_upload_statuses/success',
  ERROR: '/cloud_resource_upload_statuses/failed',
  PENDING: '/cloud_resource_upload_statuses/pending',
  NOT_FOUND: '/cloud_resource_upload_statuses/not_found',
  NOT_VERIFIED: '/cloud_resource_upload_statuses/not_verified',
};

export const isUploadPending = status => (status === UPLOAD_STATUS.PENDING);
export const isResourceNotFound = status => (status === UPLOAD_STATUS.NOT_FOUND);
export const isSuccessfullyUploaded = status => (status === UPLOAD_STATUS.SUCCESS);
export const isUploading = status => (status === UPLOAD_STATUS.START);
export const uploadFailed = status => (status === UPLOAD_STATUS.ERROR);
export const uploadStatusUnknown = (status) => {
  return ((status !== UPLOAD_STATUS.START)
  && (status !== UPLOAD_STATUS.SUCCESS)
  && (status !== UPLOAD_STATUS.PENDING)
  && (status !== UPLOAD_STATUS.ERROR));
};

// -------------------------------------------
export const TIMEOUT_BEFORE_REDIRECT_USER = 5000;


/**
 * Maximum Lengths of different objects fields
 */
export const MAXIMUM_LENGTH = {
  // DATAKEY and DATAKEYVALUE value and technicalValue
  DATAKEY: 100,
  // Project name
  PROJECTNAME: 100,
  // Label
  LABEL: 100,
};

/**
 * Zoom levels used in the scenario
 */
export const ZOOM_LEVELS = {
  VERY_SMALL: 1,
  SMALL: 2,
  STANDARD: 3,
  BIG: 4,
  VERY_BIG: 5,
};


/**
 * DATA Transfert types
 */
export const DATATYPES = {
  DATAKEY_IRI: "skewerlab/datakeyiri",
  DATAKEYVALUE_IRI: "skewerlab/datakeyvalueiri",
};
