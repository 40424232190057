/**
 * File: dataKeysState.js
 */
import * as DataKey from '../helpers/dataKey';
import ACTIONS from './dataKeysStateConstants';
import PROJECTSACTIONS from './projectsStateConstants';
import {
  getDkIRI,
  getDkvIRI,
} from '../tools/IRITools';
import { isDKExpandedInDKPanel, getClosedDataKeys } from '../tools/DataKeyTools';
import {
  PREFERENCES,
  getPreference,
  setPreference,
} from '../helpers/preferences';

const initialState = {
  error: null,
  status: null,
  newDataKey: null,
  dataKeys: [],
  dataKeysIndexesMap: {},
  dataKeyValuesIndexesMap: {},
};

/**
 * THE REDUCER SWITCH
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESET_CURRENT_PROJECT:
    {
      return initialState;
    }
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    case ACTIONS.GET_DATAKEYS_REQUEST:
      return {
        ...state,
        status: ACTIONS.GET_DATAKEYS_REQUEST,
      };

    // CASE GET ALL DATA OF CURRENT PROJECT || GET DATAKEYS
    case PROJECTSACTIONS.GET_PROJECT_SUCCESS || ACTIONS.GET_DATAKEYS_SUCCESS:
    {
      const newDataKeysArray = DataKey.parseApiDataKey(action.dataKeys);

      const previouslyClosedKeys = getPreference(PREFERENCES.DATAKEY_PANEL_CLOSED_DATAKEYS, [], action.project);

      const newDataKeysIndexesMap = {};
      const newDataKeyValuesIndexesMap = {};
      newDataKeysArray.forEach((elem, index) => {
        newDataKeysIndexesMap[getDkIRI(elem)] = index;

        elem.dataKeyValues.forEach((dkvElem, dkvIndex) => {
          newDataKeyValuesIndexesMap[getDkvIRI(dkvElem)] = { dkIndex: index, dkvIndex };
        });

        // Was the DataKey previoulsy closed in the DataKeyPanel
        const closedidx = previouslyClosedKeys.findIndex(pck => pck === getDkIRI(elem));
        if (closedidx !== -1) {
          elem.dkPanelIExpanded = false;
        }
      });

      return {
        ...state,
        status: ACTIONS.GET_DATAKEYS_SUCCESS,
        dataKeys: newDataKeysArray,
        dataKeysIndexesMap: newDataKeysIndexesMap,
        dataKeyValuesIndexesMap: newDataKeyValuesIndexesMap,
      };
    }

    case ACTIONS.GET_DATAKEYS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.GET_DATAKEYS_FAILURE,
      };

    case ACTIONS.NEW_DATAKEY_REQUEST:
      return {
        ...state,
        status: ACTIONS.NEW_DATAKEY_REQUEST,
      };

    case ACTIONS.NEW_DATAKEY_SUCCESS:
    {
      const clonedIndexesMap = { ...state.dataKeysIndexesMap };
      clonedIndexesMap[getDkIRI(action.newDataKey)] = state.dataKeys.length;

      // Just in case, but nowaday when we got NEW_DATAKEY_SUCCESS,
      // the new DataKey does not contain ANY DataKeyValue
      const clonedDataKeyValuesIndexesMap = { ...state.dataKeyValuesIndexesMap };
      action.newDataKey.dataKeyValues.forEach((dkvElem, dkvIndex) => {
        clonedDataKeyValuesIndexesMap[getDkvIRI(dkvElem)] = {
          dkIndex: state.dataKeys.length,
          dkvIndex,
        };
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.NEW_DATAKEY_SUCCESS,
        newDataKey: action.newDataKey,
        dataKeys: [...state.dataKeys, action.newDataKey],
        dataKeysIndexesMap: clonedIndexesMap,
        dataKeyValuesIndexesMap: clonedDataKeyValuesIndexesMap,
      };
    }
    case ACTIONS.NEW_DATAKEY_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.NEW_DATAKEY_FAILURE,
      };

    case ACTIONS.NEW_DATAKEYVALUE_REQUEST:
      return {
        ...state,
        status: ACTIONS.NEW_DATAKEYVALUE_REQUEST,
      };

    case ACTIONS.NEW_DATAKEYVALUE_SUCCESS:
    {
      const newDataKeysArray = state.dataKeys.map((dataKeyItem) => {
        if (getDkIRI(dataKeyItem) === getDkIRI(action.dataKey)) {
          return {
            ...dataKeyItem,
            dataKeyValues: [...dataKeyItem.dataKeyValues, action.newDataKeyValue],
          };
        }
        return dataKeyItem;
      });

      const newDataKeysIndexesMap = {};
      const newDataKeyValuesIndexesMap = {};
      newDataKeysArray.forEach((elem, index) => {
        newDataKeysIndexesMap[getDkIRI(elem)] = index;

        elem.dataKeyValues.forEach((dkvElem, dkvIndex) => {
          newDataKeyValuesIndexesMap[getDkvIRI(dkvElem)] = { dkIndex: index, dkvIndex };
        });
      });

      return {
        ...state,
        error: null,
        status: ACTIONS.NEW_DATAKEYVALUE_SUCCESS,
        dataKeys: newDataKeysArray,
        dataKeysIndexesMap: newDataKeysIndexesMap,
        dataKeyValuesIndexesMap: newDataKeyValuesIndexesMap,
      };
    }
    case ACTIONS.NEW_DATAKEYVALUE_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.NEW_DATAKEYVALUE_FAILURE,
      };

    case ACTIONS.MODIFY_DATAKEYVALUE_REQUEST:
    {
      const datakeyIdx = state.dataKeysIndexesMap[action.dataKeyIRI];

      const newDataKeys = [...state.dataKeys];
      newDataKeys[datakeyIdx] = {
        ...state.dataKeys[datakeyIdx],
        dataKeyValues: [
          ...state.dataKeys[datakeyIdx].dataKeyValues.map((dataKeyValue) => {
            if (getDkvIRI(dataKeyValue) === getDkvIRI(action.dataKeyValueToModify)) {
              return {
                ...dataKeyValue,
                ...action.dataKeyValueToModify,
              };
            }
            return dataKeyValue;
          }),
        ],
      };

      return {
        ...state,
        status: ACTIONS.MODIFY_DATAKEYVALUE_REQUEST,
        dataKeys: newDataKeys,
      };
    }

    case ACTIONS.MODIFY_DATAKEYVALUE_SUCCESS:
    {
      const datakeyIdx = state.dataKeysIndexesMap[action.dataKeyIRI];

      const newDataKeys = [...state.dataKeys];
      newDataKeys[datakeyIdx] = {
        ...state.dataKeys[datakeyIdx],
        dataKeyValues: [
          ...state.dataKeys[datakeyIdx].dataKeyValues.map((dataKeyValue) => {
            if (getDkvIRI(dataKeyValue) === getDkvIRI(action.dataKeyValueModified)) {
              return action.dataKeyValueModified;
            }
            return dataKeyValue;
          }),
        ],
      };

      return {
        ...state,
        error: null,
        status: ACTIONS.MODIFY_DATAKEYVALUE_SUCCESS,
        dataKeys: newDataKeys,
      };
    }
    case ACTIONS.MODIFY_DATAKEYVALUE_FAILURE:
    {
      const datakeyIdx = state.dataKeysIndexesMap[action.dataKeyIRI];

      const newDataKeys = [...state.dataKeys];
      newDataKeys[datakeyIdx] = {
        ...state.dataKeys[datakeyIdx],
        dataKeyValues: [
          ...state.dataKeys[datakeyIdx].dataKeyValues.map((dataKeyValue) => {
            if (getDkvIRI(dataKeyValue) === getDkvIRI(action.dataKeyValueBeforeModification)) {
              return action.dataKeyValueBeforeModification;
            }
            return dataKeyValue;
          }),
        ],
      };

      return {
        ...state,
        error: action.error,
        status: ACTIONS.MODIFY_DATAKEYVALUE_FAILURE,
        dataKeys: newDataKeys,
      };
    }
    case ACTIONS.DELETE_DATAKEYVALUE_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_DATAKEYVALUE_REQUEST,
      };

    case ACTIONS.DELETE_DATAKEYVALUE_SUCCESS:
    {
      const newDataKeysArray = state.dataKeys.map(dataKey => ({
        ...dataKey,
        dataKeyValues: [
          ...dataKey.dataKeyValues.filter((dataKeyValue) => {
            return (getDkvIRI(dataKeyValue) !== getDkvIRI(action.dataKeyValue));
          }),
        ],
      }));

      const newDataKeysIndexesMap = {};
      const newDataKeyValuesIndexesMap = {};
      newDataKeysArray.forEach((elem, index) => {
        newDataKeysIndexesMap[getDkIRI(elem)] = index;

        elem.dataKeyValues.forEach((dkvElem, dkvIndex) => {
          newDataKeyValuesIndexesMap[getDkvIRI(dkvElem)] = { dkIndex: index, dkvIndex };
        });
      });

      return {
        ...state,
        status: ACTIONS.DELETE_DATAKEYVALUE_SUCCESS,
        dataKeys: newDataKeysArray,
        dataKeysIndexesMap: newDataKeysIndexesMap,
        dataKeyValuesIndexesMap: newDataKeyValuesIndexesMap,
      };
    }
    case ACTIONS.DELETE_DATAKEYVALUE_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_DATAKEYVALUE_FAILURE,
      };

    case ACTIONS.DELETE_DATAKEY_REQUEST:
      return {
        ...state,
        status: ACTIONS.DELETE_DATAKEY_REQUEST,
      };

    case ACTIONS.DELETE_DATAKEY_SUCCESS:
    {
      const newDataKeysArray = [...state.dataKeys];
      newDataKeysArray.splice(state.dataKeysIndexesMap[action.dataKeyIRI], 1);

      const newDataKeysIndexesMap = {};
      const newDataKeyValuesIndexesMap = {};
      newDataKeysArray.forEach((elem, index) => {
        newDataKeysIndexesMap[getDkIRI(elem)] = index;

        elem.dataKeyValues.forEach((dkvElem, dkvIndex) => {
          newDataKeyValuesIndexesMap[getDkvIRI(dkvElem)] = { dkIndex: index, dkvIndex };
        });
      });

      return {
        ...state,
        status: ACTIONS.DELETE_DATAKEY_SUCCESS,
        dataKeys: newDataKeysArray,
        dataKeysIndexesMap: newDataKeysIndexesMap,
        dataKeyValuesIndexesMap: newDataKeyValuesIndexesMap,
      };
    }

    case ACTIONS.DELETE_DATAKEY_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ACTIONS.DELETE_DATAKEY_FAILURE,
      };

    case ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_REQUEST:
    case ACTIONS.MODIFY_DATAKEY_REQUEST: {
      const newDataKeys = [...state.dataKeys];
      const dkIndex = state.dataKeysIndexesMap[getDkIRI(action.dataKeyToModify)];
      newDataKeys[dkIndex] = {
        ...state.dataKeys[dkIndex],
        ...action.dataKeyToModify,
      };

      return {
        ...state,
        status: action.type,
        dataKeys: newDataKeys,
      };
    }

    case ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_SUCCESS:
    case ACTIONS.MODIFY_DATAKEY_SUCCESS: {
      const newDataKeys = [...state.dataKeys];
      const dkIndex = state.dataKeysIndexesMap[getDkIRI(action.dataKeyModified)];
      newDataKeys[dkIndex] = { ...action.dataKeyModified };
      return {
        ...state,
        error: null,
        status: action.type,
        dataKeys: newDataKeys,
      };
    }

    case ACTIONS.SET_DATAKEY_DEFAULTKEYVALUE_FAILURE:
    case ACTIONS.MODIFY_DATAKEY_FAILURE: {
      const newDataKeys = [...state.dataKeys];
      const dkIndex = state.dataKeysIndexesMap[getDkIRI(action.dataKeyBeforeModification)];
      newDataKeys[dkIndex] = {
        ...state.dataKeys[dkIndex],
        ...action.dataKeyBeforeModification,
      };

      return {
        ...state,
        error: action.error,
        status: action.type,
        dataKeys: newDataKeys,
      };
    }

    // UI ACTIONS - modify graphical state

    case ACTIONS.SWITCH_EXPAND_COLLAPSE_DK_PANEL_STATE: {
      const newDataKeys = [...state.dataKeys];
      const dkIndex = state.dataKeysIndexesMap[action.dkIRI];
      newDataKeys[dkIndex] = {
        ...state.dataKeys[dkIndex],
      };
      newDataKeys[dkIndex].dkPanelIExpanded = !isDKExpandedInDKPanel(newDataKeys[dkIndex]);

      // Save closed keys in preferences
      setPreference(PREFERENCES.DATAKEY_PANEL_CLOSED_DATAKEYS, getClosedDataKeys(newDataKeys), action.project);

      return {
        ...state,
        status: action.type,
        dataKeys: newDataKeys,
      };
    }

    default:
      return state;
  }
};
