import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from './UIConstants';

const useStyles = makeStyles(theme => ({
  divider: {
    borderLeft: `1px solid ${Color.greyAlt}`,
    height: '25px',
    width: '0px',
  },
}));

const SkwrToolBarDivider = () => {
  const classes = useStyles();

  return (
    <div className={classes.divider} />
  );
};

export default SkwrToolBarDivider;
