import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { ColorByUsage } from './UIConstants';

export const SkwrCheckboxStyles = {
  STANDARD: 'Standard',
  PRIMARY: 'Primary',
};

const useStyles = makeStyles(theme => ({
  rootStandard: {
    color: ColorByUsage.labelForeground,
  },
  checkedStandard: {
    color: ColorByUsage.labelForeground,
  },
  rootPrimary: {
    color: ColorByUsage.labelForeground,
  },
  checkedPrimary: {
    color: ColorByUsage.checkboxPrimaryForeground,
  },
}));

const SkwrCheckbox = ({
  componentStyle = SkwrCheckboxStyles.STANDARD,
  ...props
}) => {
  const classes = useStyles();

  let classesToSet = {
    root: classes.rootStandard,
    checked: classes.checkedStandard,
  };
  if (componentStyle === SkwrCheckboxStyles.PRIMARY) {
    classesToSet = {
      root: classes.rootPrimary,
      checked: classes.checkedPrimary,
    };
  }

  return (
    <Checkbox
      color="default"
      classes={classesToSet}
      {...props}
    />
  );
};

export default SkwrCheckbox;
