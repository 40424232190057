import * as Preview from '../helpers/preview';
import ACTIONS from './previewStateConstants';
import PROJECTSACTIONS from './projectsStateConstants';
import {
  PREFERENCES,
  getPreference,
  setPreference,
} from '../helpers/preferences';

const initialState = {
  error: null,
  PLGenData: {},
  playerIsLoading: false,
  playerVolume: getPreference(PREFERENCES.PREVIEW_SOUND_VOLUME, null),
};

export const resetError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const resetPreview = () => {
  return {
    type: ACTIONS.RESET_PREVIEW,
  };
};

/**
* Generate a new preview scenario
* @param {Object} form
*/
export const generateNewPreview = projectUuid => (dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_PREVIEW_REQUEST,
    payload: 'waiting response from API ...',
  });

  Preview.createPreviewScenario(projectUuid).then((project) => {
    dispatch({
      type: ACTIONS.NEW_PREVIEW_SUCCESS,
      payload: project,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_PREVIEW_FAILURE,
      error: error.message,
    });
  });
};


export const setPlgenData = data => (dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });
  dispatch({ type: ACTIONS.SDK_REQUEST });

  if (!data) {
    dispatch(dispatchSDKError('PLGenData is empty'));
  }

  dispatch({
    type: ACTIONS.SDK_SUCCESS,
    data,
  });
};

export const dispatchSDKError = error => (dispatch) => {
  if (!error) {
    return;
  }

  dispatch({
    type: ACTIONS.SDK_ERROR,
    error: error?.message ? error.message : error,
  });
};

/**
* Send a new export request to API Gateway
* @param {Object} form
*/
export const createNewExportSingle = (projectUuid, token, replayToken, userEmail) => (dispatch) => {
  dispatch({ type: ACTIONS.CLEAR_ERROR });

  dispatch({
    type: ACTIONS.NEW_EXPORT_SINGLE_REQUEST,
    payload: 'waiting response from API ...',
  });
  Preview.createSingleExport(projectUuid, token, replayToken, userEmail).then((gatewayResponse) => {
    dispatch({
      type: ACTIONS.NEW_EXPORT_SINGLE_SUCCESS,
      data: gatewayResponse,
    });
  }).catch((error) => {
    dispatch({
      type: ACTIONS.NEW_EXPORT_SINGLE_FAILURE,
      error: error.message,
    });
  });
};

/**
 * Set the new volume of the player
 * @param {int} volume
 */
export const setPlayerVolume = newVolume => (dispatch) => {
  setPreference(PREFERENCES.PREVIEW_SOUND_VOLUME, newVolume);

  dispatch({
    type: ACTIONS.SET_PLAYER_NEW_VOLUME,
    volumeData: newVolume,
  });
};

/**
 * THE REDUCER SWITCH
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECTSACTIONS.CLEAR_ERROR:
    case ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case ACTIONS.RESET_PREVIEW:
      return {
        ...initialState,
        playerVolume: state.playerVolume,
      };
    case PROJECTSACTIONS.RESET_CURRENT_PROJECT:
    {
      return {
        ...initialState,
        playerVolume: state.playerVolume,
      };
    }

    case ACTIONS.NEW_PREVIEW_REQUEST:
      return {
        ...state,
        action: ACTIONS.NEW_PREVIEW_REQUEST,
      };
    case ACTIONS.NEW_PREVIEW_SUCCESS:
      return {
        ...state,
        action: ACTIONS.NEW_PREVIEW_SUCCESS,
      };
    case ACTIONS.NEW_EXPORT_SINGLE_REQUEST:
      return state;
    case ACTIONS.NEW_EXPORT_SINGLE_SUCCESS:
      return state;
    case ACTIONS.NEW_EXPORT_SINGLE_FAILURE:
      return state;
    case ACTIONS.SDK_REQUEST:
      return {
        ...state,
        playerIsLoading: true,
      };
    case ACTIONS.SDK_SUCCESS:
      return {
        ...state,
        PLGenData: action.data,
        playerIsLoading: false,
      };
    case ACTIONS.SDK_ERROR:
      return {
        ...state,
        playerIsLoading: false,
        error: action.error,
      };
    case ACTIONS.SET_PLAYER_NEW_VOLUME:
      return {
        ...state,
        playerVolume: action.volumeData,
      };
    default:
      return state;
  }
};
