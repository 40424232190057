/**
 * File : SkwrEditableLabel.jsx
 * SkwrEditableLabel : Label component with interactions behaviours allowing edition
 */
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ReactComponent as CloseTokenSVG } from './assets/svg/closeToken.svg';
import { ReactComponent as PenSVG } from './assets/svg/pen.svg';
import SkwrTooltip from './SkwrTooltip';
import SkwrAutosuggest from './SkwrAutosuggest';
import { ColorByUsage, FontSizes } from './UIConstants';
import HighlightableLabel from './HighlightableLabel';

const styles = theme => ({
  editableLabelComponent: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '99px',
    '&:hover': {
      '& .editableLabelButton': {
        opacity: 0.5,
        display: 'flex',

        '&:hover': {
          opacity: 1,
          display: 'flex',
        },
      },
    },
  },
  editableLabelComponentHeightSingleLine: {
    height: 'min-content',
  },
  editableLabelComponentHeightMultiLine: {
    height: '100%',
  },

  editableLabelButton: {
    display: 'none',
    marginLeft: '5px',
    padding: 0,
    border: 'none',
    outline: 'none',
    height: '15px',
    width: '15px',
    lineHeight: '15px',
    cursor: 'pointer',
    opacity: 0,
    '& svg': {
      fill: ColorByUsage.editableLabelButton,
    },
    '&:hover svg': {
      fill: ColorByUsage.editableLabelButtonHover,
    },
  },

  editableLabelInput: {
    marginLeft: '5px',
    border: 'none',
    outline: 'none',
  },
  editableLabelAutosuggest: {
    marginLeft: '0px',
    width: '100%',
  },

  editableLabelInputDiv: {
    width: '100%',
  },
  editableLabelLabelDiv: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    width: '100%',
    height: '100%',
  },

  editableLabelLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    textAlign: 'initial',
    fontSize: FontSizes.textMedium,
    whiteSpace: 'nowrap',
  },
  editableLabelTextArea: {
    overflow: 'auto',
    msOverflowStyle: 'none',
    resize: 'none',
    border: '0px',
    margin: '0px',
    padding: '0px',
    width: '100%',
    height: '100%',
    background: 'transparent',
    fontSize: FontSizes.textMedium,
  },
});

function usePrevious(value, defaultValue) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  if (ref.current) {
    return ref.current;
  }
  return defaultValue;
};

const SkwrEditableLabel = ({
  value,
  classes,
  selInput,
  selLabel,
  glowData,
  onChange,
  onDelete,
  getFocus,
  maxLength,
  multiline,
  allowEmpty,
  selMainDiv,
  displayButtons = false,
  labelWhenEmpty,
  getSuggestions,
  onValueChanged,
  suggestionField,
  backgroundColor,
  foregroundColor,
  canCreateNewValue = true,
  foregroundColorWhenEmpty,
}) => {
  const editableLabelInput = useRef(null);
  const labelRef = useRef(null);

  const [inputCurrentValue, setInputCurrentValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [editableModeState, setEditableModeState] = useState(false);
  const prevEditableModeState = usePrevious(editableModeState, false);

  const [getFocusState, setGetFocusState] = useState(getFocus);
  const prevGetFocusState = usePrevious(getFocusState, getFocus);

  useEffect(() => {
    if (getFocusState !== prevGetFocusState) {
      setEditableMode(true);
    }
  }, [getFocus]);

  useEffect(() => {
    if (((getSuggestions !== undefined) && (getSuggestions !== null))
     && (editableModeState)) {
      // RAF
    } else if ((editableModeState)
            && (!prevEditableModeState)
            && (editableLabelInput.current !== null)) {
      // When becoming editable, select all the current content
      // and give it the focus
      editableLabelInput.current.setSelectionRange(0,
        editableLabelInput.current.value.length);
        editableLabelInput.current.focus();
    }
  }, [getSuggestions, editableModeState]);

  const onSuggestionSelected = (event,
    {
      suggestionValue,
    }) => {
    saveModifiedValue(suggestionValue);
  };

  const setEditableMode = (editOrNot) => {
    if (editOrNot) {
      if ((typeof onValueChanged !== 'undefined') && (onValueChanged !== null)) {
        // force the input value to the 'value' of the label
        setInputCurrentValue(value);

        setEditableModeState(true);
      }
    } else {
      setEditableModeState(false);
    }
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion[suggestionField];
  }

  const handleBlur = () => {
    saveModifiedValue(inputCurrentValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const cancelEdition = () => {
    setInputCurrentValue(value);

    setEditableMode(false);
    if ((typeof onChange !== 'undefined') && (onChange !== null)) {
      onChange(value);
    }
  };

  const handleClickEditable = () => {
    setEditableMode(true);
  };

  const handleClickDelete = () => {
    if ((typeof onDelete !== 'undefined') && (onDelete !== null)) {
      onDelete(value);
    }
  };

  const handleInputChange = (inputvalue) => {
    setInputCurrentValue(inputvalue);

    if ((typeof onChange !== 'undefined') && (onChange !== null)) {
      onChange(inputvalue);
    }
  };

  const handleAutosuggestMouseDown = (event) => {
    event.stopPropagation();
  };

  const handleKeyDown = (event) => {
    // Prevent container to act while editable mode

    if (editableModeState) {
      // If escape is pressed during edition mode, cancel the edition
      if (event.key === 'Escape') {
        cancelEdition();
        event.preventDefault();
      }

      event.stopPropagation();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (multiline) {
        // Enter without modifiers -> validate the field
        // Enter with modifiers -> new line
        if (!event.altKey && !event.ctrlKey && !event.shiftKey) {
          event.target.blur();
        }
      } else {
        event.target.blur();
      }
    } else {
      event.target.focus();
    }
  };

  const handleClickAway = () => {
    saveModifiedValue(inputCurrentValue);
  };

  const handleDoubleClick = () => {
    setEditableMode(true);
  };

  const saveModifiedValue = (modifiedValue) => {
    /* if we cannot create new value (props) and
      the MODIFIEDVALUE is not in suggestions array (a.k.a: a new value) */
    if (!canCreateNewValue && getSuggestions && getSuggestions(modifiedValue).length < 1) {
      return;
    }

    let newValueTrimmed = modifiedValue.trim();

    // If the text entered is not identical but matchs to a suggestion
    // IE if it differs from accents or case or diacritics
    // Use the value in suggestion !
    if ((typeof getSuggestions !== 'undefined') && (getSuggestions !== null)) {
      const suggestions = getSuggestions(newValueTrimmed);
      if (suggestions.length >= 1) {
        let found = false;
        for (let i = 0; ((i < suggestions.length) && (!found)); i += 1) {
          const suggestionValue = getSuggestionValue(suggestions[i]);
          if (newValueTrimmed.localeCompare(suggestionValue, undefined, { sensitivity: 'base' }) === 0) {
            newValueTrimmed = suggestionValue;
            found = true;
          }
        }
      }
    }

    if ((!allowEmpty) && (newValueTrimmed === '')) {
      cancelEdition();
    } else {
      if (newValueTrimmed !== value) {
        onValueChanged(newValueTrimmed);
      }
      setEditableMode(false);
    }
  };// saveModifiedValue

  let displayDeleteButton = false;
  if (displayButtons && ((typeof onDelete !== 'undefined') && (onDelete !== null))) {
    displayDeleteButton = true;
  }
  let displayEditButton = false;
  if (displayButtons && ((typeof onValueChanged !== 'undefined') && (onValueChanged !== null))) {
    displayEditButton = true;
  }

  // Colors if set in props
  const bgCol = { backgroundColor };
  let fgColorLabel = { color: foregroundColor };
  
  let labelWhenNotEditable = value;
  if (((value === undefined) || (value === null) || (value === ''))
    && ((labelWhenEmpty !== undefined) && (labelWhenEmpty !== null) && (labelWhenEmpty !== ''))) {
    labelWhenNotEditable = labelWhenEmpty;

    if ((foregroundColorWhenEmpty !== undefined)
     && (foregroundColorWhenEmpty !== null)
     && (foregroundColorWhenEmpty !== '')) {
      fgColorLabel = { color: foregroundColorWhenEmpty };
    }
  }

  // Do we have suggestions to fill this input ?
  let displaySuggestions = false;
  if ((getSuggestions !== undefined) && (getSuggestions !== null)) {
    displaySuggestions = true;
  }

  let mainHeightStyle = classes.editableLabelComponentHeightSingleLine;
  if (multiline) {
    mainHeightStyle = classes.editableLabelComponentHeightMultiLine;
  }

    return (
      <div
        className={classNames(selMainDiv, classes.editableLabelComponent, mainHeightStyle)}
        style={bgCol}
      >
        {editableModeState ? (
          <div
            role="presentation"
            className={classes.editableLabelInputDiv}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              { multiline ? (
                <textarea
                  className={classNames(classes.editableLabelTextArea, selInput)}
                  value={inputCurrentValue}
                  ref={editableLabelInput}
                  onBlur={handleBlur}
                  onChange={e => handleInputChange(e.target.value)}
                  onKeyPress={e => handleKeyPress(e)}
                />
              ) : (
                <React.Fragment>
                  { displaySuggestions ? (
                    <SkwrAutosuggest
                      className={classNames(classes.editableLabelAutosuggest)}
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      onSuggestionSelected={onSuggestionSelected}
                      getSuggestionValue={getSuggestionValue}
                      autoFocus
                      inputProps={{
                        value: inputCurrentValue,
                        onChange: e => handleInputChange(e.target.value),
                        onBlur: handleBlur,
                        onKeyPress: e => handleKeyPress(e),
                        onKeyDown: e => handleKeyDown(e),
                        onMouseDown: e => handleAutosuggestMouseDown(e),
                        className: selInput,
                        maxLength,
                      }}
                    />
                  ) : (
                    <input
                      className={classNames(classes.editableLabelInput, selInput)}
                      ref={editableLabelInput}
                      value={inputCurrentValue}
                      onBlur={handleBlur}
                      onChange={e => handleInputChange(e.target.value)}
                      onKeyPress={e => handleKeyPress(e)}
                      onKeyDown={e => handleKeyDown(e)}
                      style={bgCol}
                      maxLength={maxLength}
                    />
                  )}
                </React.Fragment>
              )}
            </ClickAwayListener>
          </div>
        )
          : (
            <div
              className={classes.editableLabelLabelDiv}
              onDoubleClick={handleDoubleClick}
            >
              { multiline ? (
                <textarea
                  className={classNames(classes.editableLabelTextArea, selLabel)}
                  value={labelWhenNotEditable}
                  style={fgColorLabel}
                  readOnly
                />
              ) : (
                <>
                  { glowData && glowData.glowing ? (
                    <HighlightableLabel
                      labelText={labelWhenNotEditable}
                      glowData={glowData}
                      hllClass={fgColorLabel}
                      maxP1Length={5}
                    />
                  ) : (
                    <SkwrTooltip ellipsisref={labelRef}>
                      <label
                        className={classNames(classes.editableLabelLabel, selLabel)}
                        style={fgColorLabel}
                        ref={labelRef}
                      >
                        {labelWhenNotEditable}
                      </label>
                    </SkwrTooltip>
                  )}
                </>
              )}
              { displayEditButton && (
                <div className={classNames('editableLabelButton', classes.editableLabelButton)}>
                  <PenSVG onClick={handleClickEditable} />
                </div>
              )}
              { displayDeleteButton && (
                <div className={classNames('editableLabelButton', classes.editableLabelButton)}>
                  <CloseTokenSVG onClick={handleClickDelete} />
                </div>
              )}
            </div>
          )
        }
      </div>
    );
};

export default withStyles(styles)(SkwrEditableLabel);
