import dayjs from 'dayjs';
import 'dayjs/locale/en';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import translations from '../i18n/locales';
import CONSTANTS from '../Constants';
import { getUserLocale } from '../tools/LocaleTools';

const initialState = {
  locale: CONSTANTS.DEFAULT_LOCALE,
  messages: {},
};

const SET_LOCALE = 'locate/set';

export const setAutomaticallyLocale = () => {
  // default locale : english
  const lang = getUserLocale(CONSTANTS.DEFAULT_LOCALE, translations);

  return setLocale(lang);
};

export const setLocale = locale => (dispatch, getState) => {
  setDayJSLocale(locale);

  dispatch({
    type: SET_LOCALE,
    locale,
    messages: translations[locale],
  });
};

/**
 * Load and set locale for dayjs library
 * @param {*} locale
 */
const setDayJSLocale = (locale) => {
  import(`dayjs/locale/${locale}.js`).then(() => {
    dayjs.locale(locale);
    dayjs.extend(LocalizedFormat);
  }).catch((error) => {
    console.error(`Unable to load locale file : 'dayjs/locale/${locale}.js'`);

    // If the locale can't be loaded, use english as default one for dayjs library
    dayjs.locale('en');
    dayjs.extend(LocalizedFormat);
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
    {
      return {
        ...state,
        locale: action.locale,
        messages: action.messages,
      };
    }
    default:
      return state;
  }
};
