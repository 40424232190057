import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import * as RouterActions from 'connected-react-router';
import * as AuthenticationActions from '../reducers/authenticationState';
import * as LocaleActions from '../reducers/localeState';


class Viewport extends Component {
  componentDidMount() {
    const {
      authenticationActions,
      localeActions,
    } = this.props;

    localeActions.setAutomaticallyLocale();
    authenticationActions.loadUser();
  }

  render() {
    const { localeReducer, children } = this.props;

    return (
      <IntlProvider
        locale={localeReducer.locale}
        key={localeReducer.locale}
        messages={localeReducer.messages}
      >
        <div>
          {children}
        </div>
      </IntlProvider>
    );
  }
}

export default connect(
  state => ({
    localeReducer: state.localeReducer,
  }),
  dispatch => ({
    localeActions: bindActionCreators(LocaleActions, dispatch),
    authenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
  }),
)(Viewport);
