import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Color } from './UIConstants';

// Text content


const useStyles = makeStyles({
  dropZone: {
    borderStyle: 'dashed',
    borderWidth: '2px',
  },
  dropZoneStandard: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  dropZoneActive: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  dropZoneAccept: {
    borderColor: Color.greyAlt,
    backgroundColor: Color.greyLight,
  },
  dropZoneReject: {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  textLine: {
    flex: '1',
  },
});

export const openFileSystemDialog = (ref) => {
  if ((ref)
   && (ref.current)) {
    ref.current.open();
  }
};

const SkwrDropzone = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    className,
    children,
    multiple,
    accept,
    onDropAccepted,
    filterDoubleClick,
  } = props;

  // const dropzoneRef = useRef(null);

  // Graphical Handlers
  const handleDblClickOnDropzone = (evt) => {
    let openDialog = true;

    // In some cases (Media Library), we may want to filter double click events,
    // to avoid being annoying by too much dialogs when clicking too fast
    if (typeof filterDoubleClick !== 'undefined') {
      openDialog = filterDoubleClick(evt);
    }
    if (openDialog) {
      openFileSystemDialog(ref);
    }
  };


  return (
    <Dropzone
      ref={ref}
      noClick
      noKeyboard
      onDropAccepted={onDropAccepted}
      accept={accept}
      multiple={multiple}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        let dropZoneClass = classNames(className, classes.dropZone, classes.dropZoneStandard);
        if (isDragReject) {
          dropZoneClass = classNames(className, classes.dropZone, classes.dropZoneReject);
        } else if (isDragAccept) {
          dropZoneClass = classNames(className, classes.dropZone, classes.dropZoneAccept);
        } else if (isDragActive) {
          dropZoneClass = classNames(className, classes.dropZone, classes.dropZoneActive);
        }
        return (
          <div
            {...getRootProps({ className: dropZoneClass })}
            onDoubleClick={handleDblClickOnDropzone}
          >
            <input {...getInputProps()} />
            {children}
          </div>
        );
      }}
    </Dropzone>
  );
});

export default SkwrDropzone;
