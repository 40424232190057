import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SkwrDialogTitle from './SkwrDialogTitle';

const useStyles = makeStyles({
  actions: {
    padding: '8px 16px 16px',
  },
});

const SkwrDialog = ({
  onClose,
  title,
  children,
  actions,
  actionsProps,
  dialogContentProps,
  ...dialogProps
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      fullScreen={fullScreen}
      {...dialogProps}
    >
      <SkwrDialogTitle id="form-dialog-title" onClose={handleClose}>
        {title}
      </SkwrDialogTitle>
      <DialogContent {...dialogContentProps}>
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.actions} {...actionsProps}>
          {actions.map((component, idx) => (
            <React.Fragment key={idx}>{component}</React.Fragment>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default SkwrDialog;
