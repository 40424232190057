import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Color, ColorByUsage, IS_MACOS } from 'skewerui';
import NotAvailableDialog from '../containers/dialogs/NotAvailableDialog';
import Header from '../containers/Header/Header';
import * as UndoRedoActions from '../undoredo/actions';
import './View.scss';

import backgroundImage from '../assets/img/background-waves.jpg'; // Import using relative path

const styles = {
  mainContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  viewWorkspace: {
    background: ColorByUsage.workspaceBackground,
    backgroundSize: 'cover',
    overflow: 'hidden',
    scrollbarColor: `${Color.greyLight3} rgba(00,00,00,00)`,
  },
  viewUnlogged: {
  },
  headerContainer: {
    flexGrow: 0,
  },
  pageContainer: {
    flexGrow: 1,
    flex: 1,
    overflow: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  pageContainerUnlogged: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
  },
};

class View extends Component {
  static defaultProps = {
    loggedIn: false,
    displayLogin: true,
  }

  constructor(props) {
    super(props);
    this.handleKeyDownForUndoRedo = this.handleKeyDownForUndoRedo.bind(this); // bind function once
  }

  componentDidMount() {
    const {
      undoRedo,
    } = this.props;

    // Add EventListeners to manage Undo/Redo keyboard shortcuts
    if ((typeof undoRedo !== 'undefined')
     && (undoRedo !== null)) {
      window.addEventListener('keydown', this.handleKeyDownForUndoRedo, false);
    }
  }

  componentWillUnmount() {
    const {
      undoRedo,
    } = this.props;

    // Remove EventListeners that managed Undo/Redo keyboard shortcuts
    if ((typeof undoRedo !== 'undefined')
     && (undoRedo !== null)) {
      window.removeEventListener('keydown', this.handleKeyDownForUndoRedo, false);
    }
  }

  /**
   * Key Down Event listener used to managed UndoRedo keyboard shortcuts
   * @param {*} evt
   */
  handleKeyDownForUndoRedo(evt) {
    if (evt.type !== 'keydown') return;

    let undoShortcutHit = false;
    let redoShortcutHit = false;
    if (IS_MACOS) {
      if (evt.metaKey) {
        if (evt.keyCode === 90 || evt.key === 'z') {
          if (evt.shiftKey) {
            redoShortcutHit = true;
          } else {
            undoShortcutHit = true;
          }
        }
      }
    } else if (evt.code === 'ControlLeft' || evt.ctrlKey) {
      if (evt.keyCode === 90 || evt.key === 'z') {
        if (evt.shiftKey) {
          redoShortcutHit = true;
        } else {
          undoShortcutHit = true;
        }
      } else if (evt.keyCode === 89 || evt.key === 'y') {
        redoShortcutHit = true;
      }
    }

    if (undoShortcutHit) {
      this.undo();
    }
    if (redoShortcutHit) {
      this.redo();
    }
  }// handleKeyDown

  /**
   * Calls undo action
   */
  undo() {
    const {
      canUndo,
      undoRedo,
      undoRedoActions,
    } = this.props;
    if (canUndo
     && (typeof undoRedo !== 'undefined')
     && (undoRedo !== null)) {
      undoRedoActions.undo();
    }
  }

  /**
   * Calls redo action
   */
  redo() {
    const {
      canRedo,
      undoRedo,
      undoRedoActions,
    } = this.props;
    if (canRedo
     && (typeof undoRedo !== 'undefined')
     && (undoRedo !== null)) {
      undoRedoActions.redo();
    }
  }

  renderWaves() {
    return (
      <svg
        className="waves"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    );
  }

  render() {
    const {
      children,
      className,
      loggedIn,
      canRedo, // Remove this from the ...props given to the div element
      canUndo, // Remove this from the ...props given to the div element
      undoRedo, // Remove this from the ...props given to the div element
      undoRedoActions, // Remove this from the ...props given to the div element
      pageConnectedUser,
      displayLogin,
      contentCentered,
      classes,
      appDedicatedHeader,
      appDedicatedUserMenuItems,
      onLogOut,
      onHome,
      ...props
    } = this.props;

    let mainClassName = null;
    let pageContainerClassname = null;
    if (loggedIn) {
      mainClassName = classNames(className, classes.viewWorkspace, classes.mainContainer);
      pageContainerClassname = classes.pageContainer;
    } else {
      mainClassName = classNames(className, classes.viewUnlogged, classes.mainContainer);
      pageContainerClassname = classNames(classes.pageContainer, classes.pageContainerUnlogged);
    }

    return (
      <div
        className={mainClassName}
        {...props}
      >
        <Header
          className={classes.headerContainer}
          pageConnectedUser={pageConnectedUser}
          displayLogin={displayLogin}
          appDedicatedHeader={appDedicatedHeader}
          appDedicatedUserMenuItems={appDedicatedUserMenuItems}
          onLogOut={onLogOut}
          onHome={onHome}
        />
        <NotAvailableDialog />
        <div className={pageContainerClassname}>
          {contentCentered && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            >
              {children}
              {!loggedIn && (
                this.renderWaves()
              )}
            </div>
          )}
          {!contentCentered && (
            <React.Fragment>
              {children}
              {!loggedIn && (
                this.renderWaves()
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  canUndo: state.undoHistory.canUndo,
  canRedo: state.undoHistory.canRedo,
}), dispatch => ({
  undoRedoActions: bindActionCreators(UndoRedoActions, dispatch),
}))(withStyles(styles)(View));
