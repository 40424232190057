import React from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SkwrTooltip from './SkwrTooltip';

const useStyles = makeStyles(theme => ({
  tool: {
    '&:disabled': {
      opacity: 0.5,
    },
  },
  icon: {
    height: '25px',
    width: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SkwrTool = ({
  className,
  onClick,
  tooltip,
  disabled = false,
  iconClassName = null,
  size,
  SVGIcon,
}) => {
  const classes = useStyles();

  return (
    <SkwrTooltip title={tooltip} disabled={disabled}>
      <IconButton
        className={classNames(classes.tool, className)}
        onClick={onClick}
        disabled={disabled}
        size={size}
      >
        <div className={classNames(classes.icon, iconClassName)}>
          <SVGIcon />
        </div>
      </IconButton>
    </SkwrTooltip>

  );
};

export default SkwrTool;
