import axios from 'axios';
import CONSTANTS from '../Constants';
import authHeader from './auth-header';
import rejectUnfilteredError from './helperTools';
import { getAssetIRI } from '../tools/IRITools';

export const updateAsset = (asset, assetData) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}${getAssetIRI(asset)}`, assetData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        } else if (error.response.status === 500) {
          if (error.response.data['@type'] === 'hydra:Error') {
            if (error.response.data['hydra:title'] === 'AwsAssetStartTranscodeJobFailedException') {
              if (typeof error.response.data['hydra:description'] !== 'undefined') {
                console.error(error.response.data['hydra:description']);
              }
              reject(Error('updateAsset.error.AwsAssetStartTranscodeJobFailedException'));
              return;
            }
          }
        }

        rejectUnfilteredError('updateAsset', error, reject);
      }
    });
  });
};

export const createAsset = (assetData) => {
  return new Promise((resolve, reject) => {
    axios.post(`${CONSTANTS.API.PROJECTS_URI}/assets`, assetData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }

        rejectUnfilteredError('createAsset', error, reject);
      }
    });
  });
};

export const getAsset = (assetIRI) => {
  return new Promise((resolve, reject) => {
    axios.get(`${CONSTANTS.API.PROJECTS_URI}${assetIRI}`, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        rejectUnfilteredError('getAsset', error, reject);
      });
  });
};

export const deleteAsset = (asset) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${CONSTANTS.API.PROJECTS_URI}${getAssetIRI(asset)}`, {
      headers: authHeader(),
    })
      .then((response) => {
        resolve(response.data);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            if (error.response.data['@type'] === 'DeleteAssetUsedInIncompleteExportException') {
              reject(new Error('deleteAsset.error.assetUsedInRunningExport'));
              return;
            }
          }
        }
        rejectUnfilteredError('deleteAsset', error, reject);
      });
  });
};

export const getAssets = (projectUuid, filterParams, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    let params = {};
    if (typeof filterParams !== 'undefined') {
      params = { ...filterParams };
    }
    params['project.uuid'] = projectUuid;
    params.page = pageNumber;
    axios.get(`${CONSTANTS.API.PROJECTS_URI}/assets`, {
      headers: authHeader(),
      params,
    })
      .then((response) => {
        const hydraView = response.data['hydra:view'];

        const assets = response.data['hydra:member'];

        // The request is paginated !
        // If we do not have everything, get next page !
        if ((typeof hydraView['hydra:next'] !== 'undefined')
         && (hydraView['hydra:next'] !== null)) {
          console.debug('Get next page of assets : page ', pageNumber + 1);
          getAssets(projectUuid, filterParams, pageNumber + 1).then((data) => {
            assets.push(...data.assets);

            const assetsData = {
              assets,
            };

            resolve(assetsData);
          });
        } else {
          const assetsData = {
            assets,
          };

          resolve(assetsData);
        }
      }).catch((error) => {
        rejectUnfilteredError('getAssets', error, reject);
      });
  });
};

export const uploadAsset = (assetsData, fileToUpload) => {
  return new Promise((resolve, reject) => {
    if ((typeof assetsData.awsS3Upload === 'undefined')
     || (assetsData.awsS3Upload === null)) {
      reject(Error('uploadAsset.error.uploadInformationsNotFound'));
    }
    const formData = new FormData();
    formData.append('key', assetsData.awsS3Upload.formInputs.key);
    formData.append('acl', assetsData.awsS3Upload.formInputs.acl);
    formData.append('Policy', assetsData.awsS3Upload.formInputs.Policy);
    formData.append('X-Amz-Credential', assetsData.awsS3Upload.formInputs['X-Amz-Credential']);
    formData.append('X-Amz-Algorithm', assetsData.awsS3Upload.formInputs['X-Amz-Algorithm']);
    formData.append('X-Amz-Date', assetsData.awsS3Upload.formInputs['X-Amz-Date']);
    formData.append('X-Amz-Signature', assetsData.awsS3Upload.formInputs['X-Amz-Signature']);
    formData.append('file', fileToUpload);
    axios.post(`${assetsData.awsS3Upload.formAttributes.action}`, formData, {
      headers: {
        'Content-Type': ['multipart/form-data'],
      },
    })
      .then((response) => {
        const assetData = {
          asset: response.data['hydra:member'],
        };
        resolve(assetData);
      }).catch((error) => {
        rejectUnfilteredError('getasset', error, reject);
      });
  });
};

export const updateAssetThumbnail = (assetThumbnailId, assetData) => {
  return new Promise((resolve, reject) => {
    axios.put(`${CONSTANTS.API.PROJECTS_URI}/asset_thumbnails/${assetThumbnailId}`, assetData, {
      headers: authHeader(),
    }).then((response) => {
      resolve(response.data);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data['@type'] === 'ConstraintViolationList') {
            if (error.response.data.violations[0].message !== '') {
              console.error(error.response.data.violations[0].message);
            }
          }
        }
        rejectUnfilteredError('updateAssetThumbnail', error, reject);
      }
    });
  });
};
