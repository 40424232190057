/**
 * nodesSelectors.js
 * Contains all the selectors of the nodesState reducer
 */
import CONSTANTS from '../Constants';
import ACTIONS from './nodesStateConstants';
import PROJECTSACTIONS from './projectsStateConstants';
import {
  getConnectableIRI,
  getAudioMediaTypeIRI,
  getVideoMediaTypeIRI,
  getDkIRI,
} from '../tools/IRITools';
import { isKeyInstance } from '../tools/ConnectableTools';
import { getLabelFromIRI } from './labelsSelectors';
import { getCurrentProject } from './projectsSelectors';

/**
 * NODES STATE SELECTORS
 */

/**
 * Get the connectables ARRAY of the current project
 * @param {*} state The State
 */
export const getCurrentConnectables = state => state.nodes.connectables;
/**
 * Get the video connectables ARRAY of the current project
 * @param {*} state The State
 */
export const getVideoConnectables = (state) => {
  const project = getCurrentProject(state);
  if ((typeof project === 'undefined') || (project === null)) {
    return [];
  }

  const videoMediaTypeIRI = getVideoMediaTypeIRI(project);
  const videoConnectables = [];
  for (let i = 0; i < state.nodes.connectables.length; i += 1) {
    if (state.nodes.connectables[i].mediaType === videoMediaTypeIRI) {
      videoConnectables.push(state.nodes.connectables[i]);
    }
  }

  return videoConnectables;
};

/**
 * Get the audio connectables ARRAY of the current project
 * @param {*} state The State
 */
export const getAudioConnectables = (state) => {
  const project = getCurrentProject(state);
  if ((typeof project === 'undefined') || (project === null)) {
    return [];
  }

  const audioMediaTypeIRI = getAudioMediaTypeIRI(project);
  const audioConnectables = [];
  for (let i = 0; i < state.nodes.connectables.length; i += 1) {
    if (state.nodes.connectables[i].mediaType === audioMediaTypeIRI) {
      audioConnectables.push(state.nodes.connectables[i]);
    }
  }

  return audioConnectables;
};

/**
 * Get the links ARRAY of the current project
 * @param {*} state The State
 */
export const getCurrentLinks = state => state.nodes.links;

/**
 * Get the rootConnectables OBJECT of the current project
 * @param {*} state The State
 */
export const getRootConnectables = (state) => {
  return state.nodes.rootConnectables;
};
/**
 * Get the IRI of the Audio rootConnectables of the current project
 * @param {*} state The State
 */
export const getAudioRootConnectableIRI = (state) => {
  const rootCons = getRootConnectables(state);
  let audioIRI = null;
  if ((typeof rootCons !== 'undefined')
   && (rootCons !== null)
   && (typeof rootCons.audioRootConnectable !== 'undefined')
   && (rootCons.audioRootConnectable !== null)) {
    audioIRI = rootCons.audioRootConnectable;
  }
  return audioIRI;
};

/**
 * Get assets Id of a Connectable
 * @param {Object} state State
 * @param {String} connectableId Id of a connectable
 * @returns Return ARRAY of assetsId OR null
 */
export const getConnectableAssetsSelector = (state) => {
  return (connectableId) => {
    if (state && connectableId) {
      // get Connectable by id
      const Connectable = getConnectableFromIRI(state, connectableId);

      if (Connectable && Connectable.label) {
        // get Label by iri - Connectable.label === labelIRI
        const label = getLabelFromIRI(state, Connectable.label);

        if (label && label.assets) {
          // get ARRAY of assetsIRI
          const { assets } = label;

          // return ARRAY of assetsIRI
          return assets;
        }
      }
    }

    return null;
  };
};

/**
 * Get a SELECTOR that will allow to get a connectable given its IRI
 * @param {*} state The State
 */
export const getConnectableFromIRISelector = (state) => {
  return (iri) => {
    const index = state.nodes.connectablesIndexesMap[iri];
    if ((typeof index === 'undefined')
     || (index === null)) {
      console.error('Unknow connectable ', iri);
    }
    return state.nodes.connectables[index];
  };
};
export const getConnectableFromIRI = (state, iri) => {
  const func = getConnectableFromIRISelector(state);
  return func(iri);
};

/**
 * Get a SELECTOR that will allow to get a link given its IRI
 * @param {*} state The State
 */
export const getLinkFromIRISelector = (state) => {
  return (iri) => {
    const index = state.nodes.linksIndexesMap[iri];
    return state.nodes.links[index];
  };
};
export const getLinkFromIRI = (state, iri) => {
  const func = getLinkFromIRISelector(state);
  return func(iri);
};

/**
 * Get the status of Nodes state of the current project
 * @param {*} state The State
 */
export const getNodesStatus = state => state.nodes.status;
export const areNodesLoaded = state => (state.nodes.status === PROJECTSACTIONS.GET_PROJECT_SUCCESS);

/**
 * Get the error of Nodes state of the current project
 * @param {*} state The State
 */
export const getNodesError = state => state.nodes.error;

/**
 * Get the current minimum position of Nodes
 * @param {*} state The State
 */
export const getNodesMinPosition = state => state.nodes.minPos;

/**
 * Get the current maximum position of Nodes
 * @param {*} state The State
 */
export const getNodesMaxPosition = state => state.nodes.maxPos;

/**
 * Get a SELECTOR that will allow to get an array of KeyInstances given a DataKey
 * @param {*} state The State
 */
export const getInstancesOfDataKeySelector = (state) => {
  return (dk) => {
    const keyInstances = [];

    if ((typeof state.nodes.connectables === 'undefined')
     || (state.nodes.connectables === null)
     || (state.nodes.connectables.length === 0)) {
      return keyInstances;
    }

    state.nodes.connectables.forEach((conElem) => {
      if (conElem[CONSTANTS.TYPE_FIELD] === CONSTANTS.TYPES.KEYINSTANCE) {
        if (conElem.dataKey === getDkIRI(dk)) {
          keyInstances.push(conElem);
        }
      }
    });

    return keyInstances;
  };
};

/**
 * Get a SELECTOR that will retrieve the unique target of a keyInstance targets
 * IF, OF COURSE, THEY HAVE SAME TARGET
 * If THEY DON'T : NULL
 * @param {*} state The State
 */
export const getTargetOfKeyInstanceTargetsSelector = (state) => {
  return (kiTargets) => {
    let targetOfTargets = null;
    let tmpTargetIRI = null;

    for (let i = 0; i < kiTargets.length; i += 1) {
      const targetOfKI = kiTargets[i];

      if (targetOfKI[CONSTANTS.TYPE_FIELD] === CONSTANTS.TYPES.KEYINSTANCE) {
        // one target of the keyInstance is a keyInstance, don't go further, it won't merge
        tmpTargetIRI = null;
        break;
      } else if ((typeof targetOfKI.outputLinks === 'undefined')
         || (targetOfKI.outputLinks === null)
         || (targetOfKI.outputLinks.length === 0)) {
        // If the Target has no outputlink, don't go further, it won't merge too
        tmpTargetIRI = null;
        break;
      } else {
        // Get the outputlink(s) of the target and check if it is the same as the previous target
        const targetIRI = targetOfKI.outputLinks[0].to;
        if (tmpTargetIRI === null) {
          tmpTargetIRI = targetIRI;
        } else if (tmpTargetIRI !== targetIRI) {
          tmpTargetIRI = null;
          break;
        }
      }
    }// for outputlinks

    if (tmpTargetIRI !== null) {
      const targetindex = state.nodes.connectablesIndexesMap[tmpTargetIRI];
      targetOfTargets = state.nodes.connectables[targetindex];
    }

    return targetOfTargets;
  };
};
export const getTargetOfKeyInstanceTargets = (state, kiTargets) => {
  const func = getTargetOfKeyInstanceTargetsSelector(state);
  return func(kiTargets);
};

/**
 * Get a SELECTOR that will retrieve the array of targets of a keyInstance
 * @param {*} state The State
 */
export const getKeyInstanceTargetsSelector = (state) => {
  return (ki) => {
    const targets = [];

    for (let i = 0; i < ki.outputLinks.length; i += 1) {
      const outlnk = ki.outputLinks[i];
      const toindex = state.nodes.connectablesIndexesMap[outlnk.to];
      targets.push(state.nodes.connectables[toindex]);
    }// for outputlinks

    return targets;
  };
};
export const getKeyInstanceTargets = (state, keyInstance) => {
  const func = getKeyInstanceTargetsSelector(state);
  return func(keyInstance);
};

/**
 * Indicates if a request is still running
 * Used to prevent multiple request a time
 * @param {*} state current state
 */
export const isNodeRequestStillRunning = (state) => {
  let requestRunning = false;
  if ((state.projects.status === PROJECTSACTIONS.GET_PROJECT_REQUEST)
   || (state.nodes.status === ACTIONS.NEW_NODE_REQUEST)
   || (state.nodes.status === ACTIONS.CREATE_FORK_REQUEST)
   || (state.nodes.status === ACTIONS.CREATE_OPTIONAL_NODES_REQUEST)
   || (state.nodes.status === ACTIONS.CHANGE_CONNECTABLES_COORDINATES_REQUEST)
   || (state.nodes.status === ACTIONS.CHANGE_NODE_LABEL_REQUEST)
   || (state.nodes.status === ACTIONS.PERSONNALIZE_NODE_REQUEST)
   || (state.nodes.status === ACTIONS.CHANGE_LINKS_TARGET_REQUEST)
   || (state.nodes.status === ACTIONS.LINK_CONNECTABLES_REQUEST)
   || (state.nodes.status === ACTIONS.DELETE_LINKS_REQUEST)
   || (state.nodes.status === ACTIONS.DELETE_CONNECTABLE_REQUEST)
   || (state.nodes.status === ACTIONS.MODIFY_PROJECT_VIDEO_ROOT_REQUEST)) {
    requestRunning = true;
  }
  return requestRunning;
};

/**
 * Get the selected connectables ARRAY of the current project
 * @param {*} state The State
 */
export const getSelectedConnectables = (state) => {
  return getSelectedConnectablesInArray(state.nodes.connectables);
};

/**
 * Get the selected connectables ARRAY in a given a connectables array
 * @param {*} connectables array in which we have to find the selected connectables
 */
export const getSelectedConnectablesInArray = (connectables) => {
  const selectedConnectables = [];
  for (let i = 0; i < connectables.length; i += 1) {
    if ((typeof connectables[i].selected !== 'undefined')
     && (connectables[i].selected !== null)
     && (connectables[i].selected)) {
      selectedConnectables.push(connectables[i]);
    }
  }
  return selectedConnectables;
};

/**
 * Get the array of nodes using the given label IRI
 */
export const getNodesFromLabelIRISelector = (state) => {
  return (iri) => {
    const nodes = [];

    if ((typeof state.nodes.connectables === 'undefined')
     || (state.nodes.connectables === null)
     || (state.nodes.connectables.length === 0)) {
      return nodes;
    }

    state.nodes.connectables.forEach((conElem) => {
      if ((typeof conElem.label !== 'undefined')
       && (conElem.label !== null)
       && (conElem.label === iri)) {
        nodes.push({ ...conElem });
      }
    });

    return nodes;
  };
};
export const getNodesFromLabelIRI = (state, iri) => {
  const func = getNodesFromLabelIRISelector(state);
  return func(iri);
};


/**
 * Retrieves the IRI of the DataKeyInstance forking the soundtrack
 * @param {*} state
 */
export const getDkiforkingSoundtracks = (state) => {
  let dkiFollowingRoot = null;
  const audioRootConnectableIRI = getAudioRootConnectableIRI(state);
  if (audioRootConnectableIRI) {
    const rootNode = getConnectableFromIRI(state, audioRootConnectableIRI);

    // Find all the dataKeyInstances and nodes to delete
    if ((typeof rootNode !== 'undefined')
     && (rootNode !== null)
     && (typeof rootNode.outputLinks !== 'undefined')
     && (rootNode.outputLinks !== null)
     && (rootNode.outputLinks.length !== 0)) {
      // Audio root has some output links

      const dkiIRIFollowingRoot = rootNode.outputLinks[0].to;
      const dki = getConnectableFromIRI(state, dkiIRIFollowingRoot);
      if ((typeof dki !== 'undefined')
        && (dki !== null)
        && (isKeyInstance(dki))) {
        dkiFollowingRoot = dki;
      }
    }
  }
  return dkiFollowingRoot;
};

/**
 * Retrieves the array of connectables that needs to be deleted if we want to restore a
 * single audio track in the project
 * @param {*} state
 */
export const getConnectablesToDeleteToRestoreSingleTrack = (state) => {
  const connectablesToDelete = [];
  const connectablesIRIToDelete = [];
  const linksToDelete = [];
  const dkiForkingSoundtracks = getDkiforkingSoundtracks(state);
  if (dkiForkingSoundtracks) {
    // The first (And only ?) target is a dataKeyInstance
    const dki = dkiForkingSoundtracks;

    if ((typeof dki.inputLinks !== 'undefined')
     && (dki.inputLinks !== null)
     && (dki.inputLinks.length !== 0)) {
      linksToDelete.push(...dki.inputLinks);
    }
    connectablesToDelete.push(dki);
    connectablesIRIToDelete.push(getConnectableIRI(dki));

    if ((typeof dki.outputLinks !== 'undefined')
     && (dki.outputLinks !== null)
     && (dki.outputLinks.length !== 0)) {
      linksToDelete.push(...dki.outputLinks);
      dki.outputLinks.forEach((lnk) => {
        const toCon = getConnectableFromIRI(state, lnk.to);

        if ((typeof toCon !== 'undefined')
          && (toCon !== null)) {
          connectablesToDelete.push(toCon);
          connectablesIRIToDelete.push(lnk.to);
        }
      });
    }
  }

  return {
    connectablesToDelete,
    connectablesIRIToDelete,
    linksToDelete,
  };
};// getConnectablesToDeleteToRestoreSingleTrack


export const isForkingSoundTrackRequestRunning = (state) => {
  return state.nodes.forkingSoundTrackRequestRunning;
};
